import { Button, Flex } from "@sahadevia/ui-kit-modern-sahadevia";
import { Row } from "react-flexbox-grid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import {
  addNewCollaborators,
  getCollaborators,
} from "../../actions/collaboratorAction";

import { TbaseReducer } from "../../types/reducers/reducersType";

import CollaboratorCard from "../../components/dashboard/collaborators/CollaboratorCard";
import ModalCollaborators from "../../components/dashboard/modal/ModalCollaborators";
import { toast } from "react-toastify";

function Users() {
  const dispatch = useAppDispatch();
  const [showCollaboratorsModal, setShowCollaboratorsModal] = useState(false);
  const [newCollaborator, setNewCollaborator] = useState("");

  const { collaborators } = useSelector(
    (state: TbaseReducer) => state.collaboratorsReducer
  );

  useEffect(() => {
    dispatch(getCollaborators());
  }, []);

  const onNewCollaborator = (newCollaborator: string) => {
    dispatch(
      addNewCollaborators(newCollaborator, (result) => {
        if (result.success && !result.isExist) {
          setShowCollaboratorsModal(false);
          toast(
            `Une demande de collaboration à été envoyée à ${newCollaborator}`
          );
          setNewCollaborator("");
        } else if (result.success && result.isExist) {
          toast("Cette adresse e-mail est déjà en collaboration.");
        }
      })
    );
  };

  return (
    <>
      <Flex classNames="justify-between">
        <h2 className="m-0">Collaborateurs</h2>
        <Button
          text="Ajouter un collaborateur"
          onClick={() => setShowCollaboratorsModal(true)}
        />
      </Flex>
      <Row style={{ rowGap: 16 }}>
        {collaborators.map((collaborator, idx) => (
          <CollaboratorCard collaborator={collaborator} key={idx} />
        ))}
      </Row>
      {showCollaboratorsModal ? (
        <ModalCollaborators
          newCollaborator={newCollaborator}
          setNewCollaborator={setNewCollaborator}
          onClose={() => setShowCollaboratorsModal(false)}
          onNewCollaborator={onNewCollaborator}
        />
      ) : null}
    </>
  );
}

export default Users;
