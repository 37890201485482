import Card from "./general/Card";

type LineProps = {
  title: string;
  date: string;
  from: string;
};

function Line({ title, date, from }: LineProps) {
  return (
    <div
      className="d-flex flex-column p-5 pl-20 pr-20 cursor-pointer"
      style={{
        borderBottomStyle: "solid",
        borderBottomColor: "#CDCDCD",
        borderBottomWidth: 1,
        backgroundColor: "#FFFEFB",
      }}
    >
      <span
        style={{
          color: "#3C3C3C",
          fontSize: 15,
        }}
      >
        {title}
      </span>
      <span
        style={{
          color: "#ADADAD",
          fontSize: 15,
        }}
      >
        {from} {date}
      </span>
    </div>
  );
}

type MessagesProps = {
  data: Array<any>;
};

function Messages({ data }: MessagesProps) {
  return (
    <Card title="Messages">
      {data.length ? (
        <>
          {data.map((message, idx) => (
            <Line
              key={idx}
              title={message.title}
              date={message.date}
              from={message.from}
            />
          ))}
        </>
      ) : (
        <span
          className="m-auto"
          style={{
            color: "#ADADAD",
            textAlign: "center",
          }}
        >
          Aucun message
        </span>
      )}
    </Card>
  );
}

export default Messages;
