import { Button, Flex, InputText } from "@sahadevia/ui-kit-modern-sahadevia";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import globalStyles from "../../styles/global.module.css";

import { useAppDispatch } from "../../reducers";

import GeneralLayout from "../../layouts/GeneralLayout";

import {
  getTokenData,
  validateNewCollaborators,
} from "../../actions/collaboratorAction";

import { TCollaboratorFormData } from "../../types/collaborators/collaboratorsType";

export default function NewCollaborator() {
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<TCollaboratorFormData>({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
  });
  const [inputError, setInputError] = useState<
    Record<keyof TCollaboratorFormData, boolean>
  >({
    email: false,
    name: false,
    password: false,
    confirmPassword: false,
  });

  const { email, name, password, confirmPassword } = formData;

  useEffect(() => {
    dispatch(
      getTokenData({ token: token! }, (result) => {
        if (result.success) {
          const { email } = result.data as { email: string };
          setFormData((prev) => ({ ...prev, ["email"]: email }));
        } else {
          toast(
            "Une erreur est survenue. Veuillez demander une autre invitation."
          );
        }
      })
    );
  }, []);

  const handleChange = (key: keyof TCollaboratorFormData, value: string) => {
    if (inputError[key]) {
      updateInputError(key, false);
    }
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const updateInputError = (
    key: keyof TCollaboratorFormData,
    value: boolean
  ) => {
    setInputError((prev) => ({ ...prev, [key]: value }));
  };

  const onValidate = () => {
    if (!email) {
      updateInputError("email", true);
      return;
    }
    if (!name) {
      updateInputError("name", true);
      return;
    }
    if (password !== confirmPassword || !password || !confirmPassword) {
      updateInputError("confirmPassword", true);
      return;
    }

    dispatch(
      validateNewCollaborators({ ...formData, token: token! }, (result) => {
        if (result.success && !result.isExist) {
          navigate("/");
        } else if (result.success && result.isExist) {
          toast("Vous avez déjà un compte avec cette adresse e-mail.");
        }
      })
    );
  };

  return (
    <GeneralLayout>
      <Flex direction="column" gap={10} styles={{ maxWidth: "500px" }}>
        <Flex justify="center" direction="column">
          <h1 className={classNames(globalStyles.global_title, {})}>
            Inscription
          </h1>
          <p style={{ textAlign: "center" }}>
            completer les informations et valider pour pouvoir vous connecter et
            commencer la collaboration
          </p>
        </Flex>
        <InputText
          error={inputError.email}
          disabled
          type="email"
          value={email}
          onChange={() => {}}
          placeholder={"Email"}
          iconLeft={{ name: "Envelope" }}
        />
        <InputText
          error={inputError.name}
          type="text"
          value={name}
          onChange={({ target }) => handleChange("name", target.value)}
          placeholder={"Nom Prénom"}
          iconLeft={{ name: "User" }}
        />
        <InputText
          error={inputError.confirmPassword}
          type={"password"}
          value={password}
          onChange={({ target }) => handleChange("password", target.value)}
          placeholder={"Mot de passe"}
          iconLeft={{ name: "LockClose" }}
        />
        <InputText
          error={inputError.confirmPassword}
          type="password"
          value={confirmPassword}
          onChange={({ target }) =>
            handleChange("confirmPassword", target.value)
          }
          placeholder={"Confirmer votre mot de passe"}
          iconLeft={{ name: "LockClose" }}
        />
        <Flex align="center" justify="center">
          <Button text={"Valider"} onClick={onValidate} />
        </Flex>
      </Flex>
    </GeneralLayout>
  );
}
