import { _fetch } from "../utils/fetch";

export const getHousings = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/housing/get-housings",
      "GET-HOUSINGS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const addHousing = (
  address: string,
  zipCode: string,
  city: string,
  number: string,
  rent: string,
  charge: string,
  room: string,
  size: string,
  group: string,
  reference: string,
  callback: () => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/housing/add-housing",
      "ADD-HOUSING",
      JSON.stringify({
        address,
        zipCode,
        city,
        number,
        rent,
        charge,
        room,
        size,
        group,
        reference,
      }),
      dispatch,
      callback
    );
  };
};

export const assignTenant = (tenantId: number, housingId: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/housing/assign-tenant",
      "ASSIGN-TENANT",
      JSON.stringify({
        tenantId,
        housingId,
      }),
      dispatch
    );
  };
};
