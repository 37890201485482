import { Card } from "@sahadevia/ui-kit-modern-sahadevia";

function Subscription() {
  return (
    <Card title="Mon abonnement">
      <p>Abonnement 0,00 € / mois (bêta)</p>
    </Card>
  );
}

export default Subscription;
