/* Libs */
import {
  Button,
  Flex,
  Icon,
  InputText,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { Dispatch, useState } from "react";
import classNames from "classnames";

/* Styles */
import styles from "../../styles/auth/ForgotPassword.module.css";
import globalStyles from "../../styles/global.module.css";

/* Reducers */
import { useAppDispatch } from "../../reducers";
import { resetPassword } from "../../actions/authAction";
import { toast } from "react-toastify";

type Props = {
  setShowForgotPassword: Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
};

const ForgotPassword = ({ setShowForgotPassword, isMobile }: Props) => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [errorInput, setErrorInput] = useState(false);
  const [mailIsSend, setMailIsSend] = useState(false);

  const onResetPassword = (email: string) => {
    dispatch(
      resetPassword(email, (result) => {
        if (result.success) {
          setMailIsSend(true);
        }
      })
    );
  };

  return (
    <div
      className={classNames(styles.auth_forgot_password_main_container, {
        [styles.auth_forgot_password_main_container_mobile]: isMobile,
      })}
    >
      {!mailIsSend ? (
        <>
          <h1
            className={classNames(globalStyles.global_title, {
              [globalStyles.global_title_mobile]: isMobile,
            })}
          >
            Mot de passe oublié
          </h1>
          <p style={{ textAlign: "center" }}>
            Veuillez saisir l’adresse Email affiliée à votre compte afin que
            nous puissions vous envoyer la procédure de réinitialisation de
            votre mot de passe.
          </p>
          <InputText
            onFocus={() => {
              if (errorInput) {
                setErrorInput(false);
              }
            }}
            error={errorInput}
            type="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            placeholder={"Email"}
            iconLeft={{ name: "Envelope" }}
          />
          {!email && errorInput ? (
            <p style={{ margin: "auto", marginLeft: 0, color: "#B71C1C" }}>
              Veuillez remplir tous champs
            </p>
          ) : null}
          <Flex direction="column" align="center" justify="center" gap={10}>
            <Button
              text={"Suivant"}
              onClick={() => {
                if (!email) {
                  setErrorInput(true);
                } else {
                  onResetPassword(email);
                }
              }}
            />
            <Flex
              align="center"
              onClick={() => setShowForgotPassword(false)}
              classNames={styles.auth_forgot_password_back_text_container}
            >
              <Icon size={20} color="#c7c7c7" icon="CaretLeft" />
              <span className={styles.auth_forgot_password_back_text}>
                revenir à la page de connexion
              </span>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <h2 className={styles.auth_forgot_password_title}>Email envoyé</h2>
          <p style={{ textAlign: "center" }}>
            Un mail à été envoyé à votre adresse Email, veuillez cliquer sur le
            lien qu’il contient pour réinitialiser votre mot de passe. Si vous
            ne recevez pas le mail veuillez cliquer{" "}
            <a
              style={{
                color: "var(--primary-bg-color)",
                textDecoration: "underline",
              }}
              className="cursor-pointer"
              onClick={() => {
                if (!email) {
                  setErrorInput(true);
                } else {
                  onResetPassword(email);
                }
              }}
            >
              ici
            </a>
            pour renvoyer le mail.
          </p>
          <Flex
            align="center"
            justify="center"
            onClick={() => setShowForgotPassword(false)}
            classNames={styles.auth_forgot_password_back_text_container}
          >
            <div>
              <Icon icon="CaretLeft" size={20} />
            </div>
            <span className={styles.auth_forgot_password_back_text}>
              revenir à la page de connexion
            </span>
          </Flex>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
