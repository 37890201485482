import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Button,
  Flex,
  InputText,
  Modal,
} from "@sahadevia/ui-kit-modern-sahadevia";

import { useAppDispatch } from "../../../reducers";

import { addHousing } from "../../../actions/housingAction";

import { InputChange } from "../../../types/general/generalType";

interface ModalHousingProps {
  onClose: () => void;
}

function ModalHousing({ onClose }: ModalHousingProps) {
  const dispatch = useAppDispatch();

  const [address, setAddress] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [rent, setRent] = useState<string>("");
  const [charge, setCharge] = useState<string>("");
  const [room, setRoom] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [reference, setReference] = useState<string>("");

  useEffect(() => {
    setReference(`${group}-${number}`);
  }, [number, group]);

  const _addHousing = () => {
    if (
      address &&
      zipCode &&
      city &&
      number &&
      rent &&
      charge &&
      room &&
      size &&
      group &&
      reference
    ) {
      dispatch(
        addHousing(
          address,
          zipCode,
          city,
          number,
          rent,
          charge,
          room,
          size,
          group,
          reference,
          () => {
            onClose();
          }
        )
      );
    } else {
      alert("Veuillez remplir tous les champs obligatoires");
    }
  };

  return (
    <Modal target="root">
      <Flex
        classNames="shadow"
        onClick={(e) => e?.stopPropagation()}
        direction="column"
        styles={{
          background: "var(--white)",
          border: "1px solid var(--light-grey)",
          padding: 25,
          borderRadius: 8,
          width: 900,
          maxHeight: "calc(100% - 40px)",
          maxWidth: "calc(100% - 40px)",
          overflowY: "auto",
          gap: 20,
        }}
      >
        <h3 className="m-0">Ajouter un logement</h3>
        <Row style={{ rowGap: 16 }}>
          <Col xs={12} md={6} lg={6}>
            <InputText
              label="Adresse"
              placeholder="1 rue de la Gare"
              value={address}
              onChange={({ target }: InputChange) => setAddress(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={2}>
            <InputText
              type="number"
              label="Code postal"
              placeholder="57000"
              value={zipCode}
              onChange={({ target }: InputChange) => setZipCode(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <InputText
              label="Ville"
              placeholder="Metz"
              value={city}
              onChange={({ target }: InputChange) => setCity(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={1}>
            <InputText
              type="number"
              label="N°"
              placeholder="1"
              value={number}
              onChange={({ target }: InputChange) => setNumber(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={2}>
            <InputText
              type="number"
              label="Loyer HC (€)"
              placeholder="300"
              value={rent}
              onChange={({ target }: InputChange) => setRent(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={2}>
            <InputText
              type="number"
              label="Total charges (€)"
              placeholder="30"
              value={charge}
              onChange={({ target }: InputChange) => setCharge(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={2}>
            <InputText
              type="number"
              label="Nb pièce(s)"
              placeholder="2"
              value={room}
              onChange={({ target }: InputChange) => setRoom(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={2}>
            <InputText
              type="number"
              label="Superficie (m2)"
              placeholder="30"
              value={size}
              onChange={({ target }: InputChange) => setSize(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={2}>
            <InputText
              label="Groupe"
              placeholder="ABC"
              value={group}
              onChange={({ target }: InputChange) => setGroup(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={2}>
            <InputText
              label="Référence"
              placeholder="ABC-1"
              value={reference}
              onChange={({ target }: InputChange) => setReference(target.value)}
              disabled
            />
          </Col>
        </Row>
        <div style={{ display: "flex", gap: 10, justifyContent: "end" }}>
          <Button text="Annuler" outline onClick={() => onClose()} />
          <Button text="Valider" onClick={() => _addHousing()} />
        </div>
      </Flex>
    </Modal>
  );
}

export default ModalHousing;
