import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import { MouseEventHandler, useState } from "react";

type RoundProps = {
  isActive: boolean;
  onClick: MouseEventHandler;
};

function Round({ isActive, onClick }: RoundProps) {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer"
      style={{
        width: 8,
        height: 8,
        flexShrink: 0,
        borderRadius: 4,
        backgroundColor: isActive ? "#FFF" : "rgba(255, 255, 255, 0.3)",
      }}
    />
  );
}

type AlertsProps = {
  data: Array<any>;
};

function Alerts({ data }: AlertsProps) {
  const [currentTab, setCurrentTab] = useState(0);

  const back = () => {
    setCurrentTab(currentTab - 1);
  };

  const next = () => {
    setCurrentTab(currentTab + 1);
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: "100%",
        height: 280,
        backgroundColor: "var(--primary-bg-color)",
        borderRadius: 8,
        padding: 20,
        color: "#fff",
      }}
    >
      {data.length ? (
        <>
          <div className="d-flex">
            <h5 style={{ fontSize: 22, margin: 0 }}>
              {data[currentTab].title}
            </h5>
            <span className="ml-auto">{data[currentTab].date}</span>
          </div>
          <p>{data[currentTab].description}</p>
          <div className="d-flex mt-auto">
            <Icon
              disabled={currentTab === 0}
              size={24}
              color="#fff"
              icon={"CaretDown"}
              onClick={back}
            />
            <div className="m-auto d-flex" style={{ gap: 4 }}>
              {data.map((element, idx) => (
                <Round
                  key={idx}
                  isActive={currentTab === idx}
                  onClick={() => setCurrentTab(idx)}
                />
              ))}
            </div>
            <Icon
              disabled={currentTab === data.length - 1}
              size={24}
              color="#fff"
              icon={"CaretDown"}
              onClick={next}
            />
          </div>
        </>
      ) : (
        <p className="m-auto">Aucune annonce active</p>
      )}
    </div>
  );
}

export default Alerts;
