type CardProps = {
  title: string;
  children?: string | JSX.Element | JSX.Element[];
  isHeight?: boolean;
};

export default function Card({ title, children, isHeight = true }: CardProps) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          height: isHeight ? 280 : "inherit",
          backgroundColor: "#FCFCFC",
          borderRadius: 8,
          boxShadow: "2px 4px 11px 0px rgba(41, 41, 41, 0.05)",
        }}
      >
        <div
          className="d-flex p-20"
          style={{
            backgroundColor: "#FFFEFB",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            boxShadow: "0px 6px 14px 0px rgba(0, 0, 0, 0.08)",
            zIndex: 10,
          }}
        >
          <div className="mr-auto">
            <h3 className="m-0">{title}</h3>
          </div>
          <div className="ml-auto"></div>
        </div>
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {children}
        </div>
        <div
          style={{
            pointerEvents: "none",
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: 70,
            borderRadius: "0px 0px 8px 8px",
            background:
              "linear-gradient(180deg, rgba(255, 254, 251, 0.00) 0%, #FFFEFB 100%)",
          }}
        />
      </div>
    </>
  );
}
