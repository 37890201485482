import { _fetch } from "../utils/fetch";

export const getDocument = (
  tenantId: number,
  housingId: number,
  type: string,
  reference: string,
  dateStart: string,
  dateEnd: string,
  rent: number,
  paid: number | undefined,
  charge: number,
  nameAdditional: string | undefined,
  valueAdditional: number | undefined,
  toName: string,
  toAddress: string,
  toZipCode: string,
  toCity: string,
  toPhone: string,
  toEmail: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/generator/get-document",
      "GET-DOCUMENT",
      JSON.stringify({
        tenantId,
        housingId,
        type,
        reference,
        dateStart,
        dateEnd,
        rent,
        paid,
        charge,
        nameAdditional,
        valueAdditional,
        toName,
        toAddress,
        toZipCode,
        toCity,
        toPhone,
        toEmail,
      }),
      dispatch
    );
  };
};
