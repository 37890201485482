import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { Fragment } from "react";
import { Col, Row } from "react-flexbox-grid";

function Tutorial() {
  const videos = [
    {
      title: "Youtube Video Player",
      url: "https://www.youtube.com/embed/lJIrF4YjHfQ?si=Y5lPDppTqDHFfXcE",
    },
    {
      title: "Youtube Video Player",
      url: "https://www.youtube.com/embed/lJIrF4YjHfQ?si=Y5lPDppTqDHFfXcE",
    },
    {
      title: "Youtube Video Player",
      url: "https://www.youtube.com/embed/lJIrF4YjHfQ?si=Y5lPDppTqDHFfXcE",
    },
  ];

  const getVideo = (data: any) => {
    return (
      <Col xs={12} md={12} lg={6}>
        <Card title={data.title}>
          <iframe
            src={data.url}
            title={data.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={true}
            style={{ border: 0 }}
            className="w-100"
            height={300}
          ></iframe>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <h2 className="m-0">Guides</h2>
      <Row style={{ rowGap: 16 }}>
        {videos.map((video: any, idx: number) => (
          <Fragment key={idx}>{getVideo(video)}</Fragment>
        ))}
      </Row>
    </>
  );
}

export default Tutorial;
