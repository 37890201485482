import { _fetch } from "../utils/fetch";

export const getHistories = (tenantId?: number, housingId?: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/history/get-histories",
      "GET-HISTORIES",
      JSON.stringify({ tenantId, housingId }),
      dispatch
    );
  };
};

export const getMails = (tenantId?: number, housingId?: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/history/get-mails",
      "GET-MAILS",
      JSON.stringify({ tenantId, housingId }),
      dispatch
    );
  };
};

export const sendHistory = (
  historyId: number,
  tenantId: number,
  type: string,
  fileToken: string,
  fileMetadata: any
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/history/send-history",
      "SEND-HISTORY",
      JSON.stringify({ historyId, tenantId, type, fileToken, fileMetadata }),
      dispatch
    );
  };
};
