import { TBankInfo } from "../types/bank/bankType";
import { _fetch } from "../utils/fetch";

export const getOrganizationDocuments = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/organization/get-documents",
      "GET-ORGANIZATION-DOCUMENTS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const downloadDocument = (
  id: string,
  callback: (result: any) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/organization/download-document",
      "DOWNLOAD-DOCUMENT",
      JSON.stringify({ id }),
      dispatch,
      callback
    );
  };
};
