import { _fetch } from "../utils/fetch";

export const getTenants = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/tenant/get-tenants",
      "GET-TENANTS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const addTenant = (
  kind: string,
  name: string,
  surname: string,
  birthday: string,
  email: string,
  phone: string,
  callback: () => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/tenant/add-tenant",
      "ADD-TENANT",
      JSON.stringify({
        kind,
        name,
        surname,
        birthday,
        email,
        phone,
      }),
      dispatch,
      callback
    );
  };
};
