import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import moment from "moment";

import { getCurrencyValue } from "../../../utils/general";

function Bank() {
  const { balance } = useSelector((state: any) => state.bankReducer);
  const { bankDate } = useSelector((state: any) => state.authReducer);

  return (
    <Card
      title={"Mon solde"}
      className="h-100"
      style={{
        color: "var(--white)",
        background:
          "linear-gradient(171deg, var(--primary-bg-color), var(--secondary-bg-color))",
        border: "none",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <p
          className="m-0"
          style={{
            color: "var(--white)",
            fontSize: "46px",
            lineHeight: "140%",
          }}
        >
          {getCurrencyValue(balance)}
        </p>
        <p
          className="m-0"
          style={{
            color: "var(--white)",
          }}
        >
          Dernière synchronisation :{" "}
          {moment(bankDate).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      </div>
    </Card>
  );
}

export default Bank;
