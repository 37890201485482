import { Col, Row } from "react-flexbox-grid";
import { Button, InputText } from "@sahadevia/ui-kit-modern-sahadevia";
import Card from "./general/Card";

function Information() {
  return (
    <Card title="Information" isHeight={false}>
      <div className="d-flex flex-column p-20">
        <Row
          style={{
            rowGap: 16,
          }}
        >
          <Col xs={12} lg={4}>
            <InputText
              iconLeft={{ name: "User" }}
              value={""}
              onChange={({ target }) => {}}
              placeholder={"Nom"}
            />
          </Col>
          <Col xs={12} lg={4}>
            <InputText
              iconLeft={{ name: "User" }}
              value={""}
              onChange={({ target }) => {}}
              placeholder={"Prénom"}
            />
          </Col>
          <Col xs={12} lg={4}>
            <InputText
              // iconLeft={{ name: "Phone" }}
              value={""}
              onChange={({ target }) => {}}
              placeholder={"N° de téléphone"}
            />
          </Col>
          <Col xs={12} lg={4}>
            <InputText
              iconLeft={{ name: "Envelope" }}
              value={""}
              onChange={({ target }) => {}}
              placeholder={"Adresse mail"}
            />
          </Col>
          <Col xs={12}>
            <Button text="Valider" />
          </Col>
        </Row>
      </div>
    </Card>
  );
}

export default Information;
