import { combineReducers } from "redux";
import { useDispatch } from "react-redux";

import { authReducer } from "./authReducer";
import { housingReducer } from "./housingReducer";
import { tenantReducer } from "./tenantReducer";
import { historyReducer } from "./historyReducer";
import { generatorReducer } from "./generatorReducer";
import { fileReducer } from "./fileReducer";
import { bankReducer } from "./bankReducer";
import { editorReducer } from "./editorReducer";
import { organizationReducer } from "./organizationReducer";
import { loaderReducer } from "./loaderReducer";
import { collaboratorsReducer } from "./collaboratorsReducer";

import { AppDispatch } from "../store";

const config = {
  authReducer,
  housingReducer,
  tenantReducer,
  historyReducer,
  generatorReducer,
  fileReducer,
  bankReducer,
  editorReducer,
  organizationReducer,
  loaderReducer,
  collaboratorsReducer,
};

const appReducer = combineReducers(config);
export const useAppDispatch: () => AppDispatch = useDispatch;

export default appReducer;
