import { Row, Col } from "react-flexbox-grid";
import { Button, Table } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import moment from "moment";

import ConnectBank from "../../components/dashboard/bank/ConnectBank";
import Balance from "../../components/dashboard/bank/Balance";
import Income from "../../components/dashboard/bank/Income";
import Outlay from "../../components/dashboard/bank/Outlay";

import { getCurrencyValue } from "../../utils/general";

import { useAppDispatch } from "../../reducers";

import { hideBankItem } from "../../actions/bankAction";

function Bank() {
  const dispatch = useAppDispatch();

  const { transactions } = useSelector((state: any) => state.bankReducer);

  return (
    <>
      <div className="d-flex">
        <h2 className="m-0">Mon compte bancaire</h2>
        <div className="ml-auto mt-auto mb-auto">
          <ConnectBank />
        </div>
      </div>
      <Row
        style={{
          rowGap: 16,
        }}
      >
        <Col xs={12} md={12} lg={4}>
          <Balance />
        </Col>
        <Col xs={12} md={12} lg={4}>
          <Income />
        </Col>
        <Col xs={12} md={12} lg={4}>
          <Outlay />
        </Col>
        <Col xs={12} md={12} lg={4}>
          <Table
            title="Opérations bancaires"
            context={null}
            columns={[
              {
                key: "title",
                label: "Libellé",
                component: (item) => (
                  <div>
                    <span className="d-flex" style={{ whiteSpace: "normal" }}>
                      {item.title}
                    </span>
                    <span
                      className="d-flex"
                      style={{ fontSize: 14, color: "#C7C7C7" }}
                    >
                      {moment(item.date).format("DD/MM/YYYY")}
                    </span>
                  </div>
                ),
              },
              {
                key: "price",
                label: "Montant",
                component: (item) => (
                  <span
                    style={{
                      color: item.price > 0 ? "var(--green)" : "var(--red)",
                    }}
                  >
                    {getCurrencyValue(item.price)}
                  </span>
                ),
              },
              {
                key: "",
                label: "Actions",
                component: (item) => (
                  <>
                    <Button
                      text={!item.hide ? "Masquer" : "Afficher"}
                      onClick={() => {
                        dispatch(hideBankItem(item.hide ? 0 : 1, item.id));
                      }}
                    />
                  </>
                ),
              },
            ]}
            data={transactions.map((transaction: any) => ({
              id: transaction.id,
              title: transaction.label,
              date: transaction.date,
              price: transaction.amount,
              hide: transaction.hide,
            }))}
          />
        </Col>
        <Col xs={12} md={12} lg={8}>
          <Table
            title="Détails de l’opération"
            context={null}
            columns={[
              {
                key: "idx",
                label: "#",
              },
            ]}
            data={[]}
          />
        </Col>
      </Row>
    </>
  );
}

export default Bank;
