import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import Card from "./general/Card";

function Cancellation() {
  return (
    <Card title="Résiliation">
      <div className="d-flex flex-column m-auto p-20">
        <span
          className="mb-20"
          style={{
            color: "#ADADAD",
            textAlign: "center",
          }}
        >
          Fonctionnalité à venir.
        </span>
        {/* <span
          className="mb-20"
          style={{
            color: "#ADADAD",
            textAlign: "center",
          }}
        >
          Vous n’avez pas de processus de résiliation en cours.
        </span>
        <div className="m-auto">
          <Button outline text="Résilier" />
        </div> */}
      </div>
    </Card>
  );
}

export default Cancellation;
