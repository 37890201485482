import { useNavigate, useParams } from "react-router-dom";
import { Button, Flex, InputText } from "@sahadevia/ui-kit-modern-sahadevia";

/* Styles */
import styles from "../../styles/auth/ResetPassword.module.css";
import { useState } from "react";
import { useAppDispatch } from "../../reducers";
import { onResetPassword } from "../../actions/authAction";
import GeneralLayout from "../../layouts/GeneralLayout";

type InputObj = {
  newPassword: string;
  comfirmPassword: string;
};

export default function ResetPassword() {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [inputData, setInputData] = useState<InputObj>({
    newPassword: "",
    comfirmPassword: "",
  });
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [inputOption, setInputOption] = useState({
    newPassword: { visible: false },
    comfirmPassword: { visible: false },
  });

  const handleChange = (key: keyof typeof inputData, value: string) => {
    setInputData((prev) => ({ ...prev, [key]: value }));
  };

  const onValidate = () => {
    const { comfirmPassword, newPassword } = inputData;
    const emptyData = Object.keys(inputData).filter(
      (key) => inputData[key as keyof InputObj] === ""
    );

    if (emptyData.length) {
      setInputErrors(emptyData);
    } else if (newPassword !== comfirmPassword) {
      setInputErrors(["comfirmPassword"]);
    } else {
      dispatch(
        onResetPassword(newPassword, token!, (res) => {
          if (res.success) {
            navigate("/");
          }
        })
      );
    }
  };

  return (
    <GeneralLayout>
      <Flex
        direction="column"
        gap={10}
        classNames={styles.auth_reset_password_input_container}
      >
        <h2 className={styles.auth_reset_password_title}>
          Nouveau mot de passe
        </h2>
        <p className={styles.auth_reset_password_text}>
          Veuillez saisir un nouveau mot de passe pour votre compte.
        </p>
        <InputText
          type={inputOption.newPassword.visible ? "text" : "password"}
          error={!!inputErrors.includes("newPassword")}
          placeholder={"Mot de passe"}
          value={inputData.newPassword}
          onChange={(e) => handleChange("newPassword", e.target.value)}
          iconRight={{
            name: inputOption.newPassword.visible ? "Lock" : "LockClose",
            onClick: () => {
              setInputOption((prev) => ({
                ...prev,
                ["newPassword"]: { visible: !prev["newPassword"].visible },
              }));
            },
          }}
        />
        <InputText
          type={inputOption.comfirmPassword.visible ? "text" : "password"}
          error={!!inputErrors.includes("comfirmPassword")}
          placeholder={"Répéter le mot de passe"}
          value={inputData.comfirmPassword}
          onChange={(e) => handleChange("comfirmPassword", e.target.value)}
          iconRight={{
            name: inputOption.comfirmPassword.visible ? "Lock" : "LockClose",
            onClick: () => {
              setInputOption((prev) => ({
                ...prev,
                ["comfirmPassword"]: {
                  visible: !prev["comfirmPassword"].visible,
                },
              }));
            },
          }}
        />
        <Flex align="center" justify="center">
          <Button text={"Valider"} onClick={onValidate} />
        </Flex>
      </Flex>
    </GeneralLayout>
  );
}
