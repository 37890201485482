import {
  Button,
  Flex,
  InputText,
  Modal,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useState } from "react";
import { Col, Row } from "react-flexbox-grid";

type Props = {
  newCollaborator: string;
  setNewCollaborator: (value: string) => void;
  onClose: () => void;
  onNewCollaborator: (newCollaborator: string) => void;
};

export default function ModalCollaborators(props: Props) {
  const { onClose, onNewCollaborator, newCollaborator, setNewCollaborator } =
    props;

  return (
    <Modal target="root" onClick={onClose}>
      <Flex
        classNames="shadow"
        onClick={(e) => e?.stopPropagation()}
        direction="column"
        styles={{
          background: "var(--white)",
          border: "1px solid var(--light-grey)",
          padding: 25,
          borderRadius: 8,
          width: 500,
          maxHeight: "calc(100% - 40px)",
          maxWidth: "calc(100% - 40px)",
          overflowY: "auto",
          gap: 20,
        }}
      >
        <h3 className="m-0">Inviter un collaborateur</h3>
        <Row>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{ maxWidth: "100%", flexBasis: "100%" }}
          >
            <InputText
              label="Adresse email du collaborateur"
              placeholder="johnDoe@gmail.com"
              value={newCollaborator}
              onChange={(e) => setNewCollaborator(e.target.value)}
            />
          </Col>
        </Row>
        <div style={{ display: "flex", gap: 10, justifyContent: "end" }}>
          <Button text="Annuler" outline onClick={onClose} />
          <Button
            text="Valider"
            onClick={() => onNewCollaborator(newCollaborator)}
          />
        </div>
      </Flex>
    </Modal>
  );
}
