import { File as FileIcon } from "@sahadevia/ui-kit-modern-sahadevia";

import { Col } from "react-flexbox-grid";

import { getUrl } from "../../../../utils/general";

interface FileProps {
  name: string;
  path: string;
  token: string;
}

function File({ name, token }: FileProps) {
  return (
    <Col xs={12} md={4} lg={3}>
      <div
        className="cursor-pointer"
        onClick={() => {
          const aElement = document.createElement("a");
          aElement.setAttribute("download", name);
          const href = `${getUrl()}/api/file/get-file/${token}`;
          aElement.href = href;
          aElement.setAttribute("target", "_blank");
          aElement.click();
          URL.revokeObjectURL(href);
        }}
        style={{
          padding: 12,
          borderRadius: 8,
          display: "flex",
          gap: 16,
          backgroundColor: "#F0F4F9",
          color: "#1f1f1f",
          alignItems: "center",
        }}
      >
        <div style={{ flexShrink: 0, display: "inline-flex" }}>
          <FileIcon size={24} color="#1f1f1f" />
        </div>
        <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {name}
        </span>
      </div>
    </Col>
  );
}

export default File;
