import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Sidebar } from "@sahadevia/ui-kit-modern-sahadevia";

import icon from "../images/logo-small.svg";

import { signout } from "../actions/authAction";

import { useAppDispatch } from "../reducers";

type DashboardProps = {
  children: JSX.Element;
};

export default function Dashboard({ children }: DashboardProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { name, email } = useSelector((state: any) => state.authReducer);

  const getInitial = (value: string) => {
    return value
      ? value
          .split(" ")
          .map((word: string) => word[0].toUpperCase())
          .join("")
      : "";
  };

  return (
    <div className="active-shadow">
      <Sidebar
        dropdownData={{
          email: email,
          grade: "Propriétaire",
          onProfilClick: () => navigate("/profil"),
          onSignoutClick: () => dispatch(signout()),
        }}
        userIcon=""
        userIconText={getInitial(name)}
        logo={icon}
        primary={[
          {
            text: "Tableau de bord",
            active: location.pathname.endsWith("/"),
            onClick: () => navigate("/"),
          },
          {
            text: "Statistiques",
            active: location.pathname.startsWith("/statistics"),
            onClick: () => navigate("/statistics"),
          },
          {
            text: "Logements",
            active: location.pathname.startsWith("/housings"),
            onClick: () => navigate("/housings"),
          },
          {
            text: "Locataires",
            active: location.pathname.startsWith("/tenants"),
            onClick: () => navigate("/tenants"),
          },
          {
            text: "Compte bancaire",
            active: location.pathname.startsWith("/bank"),
            onClick: () => navigate("/bank"),
          },
        ]}
        secondary={[
          {
            text: "Simulateurs",
            active: location.pathname.startsWith("/simulators"),
            onClick: () => navigate("/simulators"),
          },
          {
            text: "Éditeurs",
            active: location.pathname.startsWith("/editors"),
            onClick: () => navigate("/editors"),
          },
          {
            text: "Guides",
            active: location.pathname.startsWith("/tutorial"),
            onClick: () => navigate("/tutorial"),
          },
        ]}
        footer={[
          {
            text: "Collaborateurs",
            active: location.pathname.startsWith("/users"),
            onClick: () => navigate("/users"),
          },
          {
            text: "Mon compte",
            active: location.pathname.startsWith("/profil"),
            onClick: () => navigate("/profil"),
          },
          {
            text: "Mon organisation",
            active: location.pathname.startsWith("/organization"),
            onClick: () => navigate("/organization"),
          },
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100% - 75px)",
            padding: 16,
            gap: 16,
            backgroundColor: "var(--active-sidebar-color)",
          }}
        >
          {children}
        </div>
      </Sidebar>
    </div>
  );
}
