import { _fetch } from "../utils/fetch";

export const getFiles = (tenantId: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/file/get-files",
      "GET-FILES",
      JSON.stringify({ tenantId }),
      dispatch
    );
  };
};

export const getMetadataFiles = (type: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/file/get-metadata-files",
      "GET-METADATA-FILES",
      JSON.stringify({ type }),
      dispatch
    );
  };
};

export const addFile = (
  base64: string,
  type: string,
  name: string,
  extension: string,
  housingId?: number,
  tenantId?: number
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/file/add-file",
      "ADD-FILE",
      JSON.stringify({ base64, type, name, extension, housingId, tenantId }),
      dispatch
    );
  };
};
