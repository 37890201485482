import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import moment from "moment";

import { getCurrencyValue, getPercentageValue } from "../../../utils/general";

function Cashflow() {
  const { transactions } = useSelector((state: any) => state.bankReducer);

  let cashflow = 0;
  let oldCashflow = 0;

  transactions
    .filter((transaction: any) => !transaction.hide)
    .forEach((transaction: any) => {
      const amount = parseFloat(transaction.amount);

      if (
        moment(transaction.date).format("YYYY-MM") ===
        moment().subtract(1, "months").format("YYYY-MM")
      ) {
        cashflow += amount;
      } else if (
        moment(transaction.date).format("YYYY-MM") ===
        moment().subtract(2, "months").format("YYYY-MM")
      ) {
        oldCashflow += amount;
      }
    });

  return (
    <Card title={`Cashflow du mois dernier`} className="h-100">
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <p
          className="m-0"
          style={{
            color: cashflow > 0 ? "var(--green)" : "var(--red)",
            fontSize: "46px",
            lineHeight: "140%",
          }}
        >
          {getCurrencyValue(cashflow)}
        </p>
        <div>
          <p
            className="m-0"
            style={{
              color:
                cashflow / oldCashflow - 1 > 0 ? "var(--green)" : "var(--red)",
            }}
          >
            ({getPercentageValue(cashflow / oldCashflow - 1)})
          </p>
          <p
            className="m-0"
            style={{
              color: "#C7C7C7",
            }}
          >
            M-1 par rapport à M-2
          </p>
        </div>
      </div>
    </Card>
  );
}

export default Cashflow;
