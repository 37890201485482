export const initialState = {
  housings: <any>[],
};

export const housingReducer = (state = initialState, action: any) => {
  let housings = JSON.parse(JSON.stringify(state.housings));

  switch (action.type) {
    case "GET-HOUSINGS":
      return {
        ...state,
        housings: action.success ? action.data : [],
      };
    case "ADD-HOUSING":
      if (action.success) {
        housings.push(action.data);
      }

      return {
        ...state,
        housings,
      };
    case "ASSIGN-TENANT":
      if (action.success) {
        housings.forEach((housing: any) => {
          if (housing.id === action.data.housingId) {
            housing.tenantId = action.data.tenantId;
          }
        });
      }

      return {
        ...state,
        housings,
      };
    default:
      return state;
  }
};
