import { Col, Row } from "react-flexbox-grid";
import { Button, InputText } from "@sahadevia/ui-kit-modern-sahadevia";
import Card from "./general/Card";

function Security() {
  return (
    <Card title="Sécurité" isHeight={false}>
      <div className="d-flex flex-column p-20">
        <Row
          style={{
            rowGap: 16,
          }}
        >
          <Col xs={12} lg={4}>
            <InputText
              iconLeft={{ name: "Lock" }}
              type="password"
              value={""}
              onChange={({ target }) => {}}
              placeholder={"Ancien mot de passe"}
            />
          </Col>
          <Col xs={12} lg={4}>
            <InputText
              iconLeft={{ name: "Lock" }}
              type="password"
              value={""}
              onChange={({ target }) => {}}
              placeholder={"Nouveau mot de passe"}
            />
          </Col>
          <Col xs={12} lg={4}>
            <InputText
              iconLeft={{ name: "Lock" }}
              type="password"
              value={""}
              onChange={({ target }) => {}}
              placeholder={"Confirmer le mot de passe"}
            />
          </Col>
          <Col xs={12}>
            <Button text="Valider" />
          </Col>
        </Row>
      </div>
    </Card>
  );
}

export default Security;
