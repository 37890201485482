import { TResultWithData } from "../types/general/generalType";
import { _fetch } from "../utils/fetch";

export const insertEditor = (editorToken: string, type: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/editor/insert-editor",
      "UPDATE-EDITOR",
      JSON.stringify({ editorToken, type }),
      dispatch
    );
  };
};

export const updateEditor = (editorToken: string | undefined, data: any) => {
  return (dispatch: any) => {
    _fetch(
      "/api/editor/update-editor",
      "UPDATE-EDITOR",
      JSON.stringify({ editorToken, data }),
      dispatch
    );
  };
};

export const getEditors = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/editor/get-editors",
      "UPDATE-EDITORS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getEditor = (editorToken: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/editor/get-editor",
      "UPDATE-EDITOR",
      JSON.stringify({ editorToken }),
      dispatch
    );
  };
};

export const getPdf = (
  editorToken: string | undefined,
  template: string | undefined,
  callback: (res: TResultWithData) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/editor/get-editor-pdf",
      "GET-EDITOR-PDF",
      JSON.stringify({ editorToken, template }),
      dispatch,
      callback
    );
  };
};
