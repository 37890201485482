import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import background from "../images/background.png";

type TenantDesktopProps = {
  children: JSX.Element;
};

export default function TenantDesktop({ children }: TenantDesktopProps) {
  const navigate = useNavigate();

  const data = {
    name: "Noah LANFRIT",
  };

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          width: 1200,
          padding: 100,
          margin: "auto",
          backgroundSize: "cover",
        }}
      >
        <h2
          style={{
            margin: 0,
            color: "#3C3C3C",
            fontSize: "46px",
            fontWeight: "600",
            lineHeight: "130%",
          }}
        >
          {moment().format("DD")} {months[parseInt(moment().format("M")) - 1]}
        </h2>
        <h3
          style={{
            margin: 0,
            color: "var(--primary-bg-color)",
            fontSize: "46px",
            fontWeight: "600",
            lineHeight: "130%",
          }}
        >
          {moment().format("YYYY")}
        </h3>
        <div className="d-flex mb-10">
          <div className="mr-auto">
            <h4
              style={{
                margin: 0,
                color: "#3C3C3C",
                fontSize: "18px",
              }}
            >
              {data.name}
            </h4>
          </div>
          <div className="ml-auto d-flex" style={{ gap: 10 }}>
            <Icon
              color={
                window.location.pathname === "/tenant-desktop-home"
                  ? "var(--primary-bg-color)"
                  : "#3C3C3C"
              }
              size={20}
              onClick={() => navigate("/tenant-desktop-home")}
              icon="House"
            />
            <Icon
              color={
                window.location.pathname === "/tenant-desktop-user"
                  ? "var(--primary-bg-color)"
                  : "#3C3C3C"
              }
              size={20}
              onClick={() => navigate("/tenant-desktop-user")}
              icon="User"
            />
            <Icon
              color="#3C3C3C"
              size={20}
              onClick={() => {
                if (
                  window.confirm("Êtes-vous sûr de vouloir vous déconnecter ?")
                ) {
                }
              }}
              icon="Signout"
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
