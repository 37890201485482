import {
  TCollaborator,
  TCollaboratorsInitialState,
} from "../types/collaborators/collaboratorsType";

export const initialState = {
  collaborators: [] as TCollaborator[],
} satisfies TCollaboratorsInitialState;

export const collaboratorsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET-COLLABORATORS":
      return {
        ...state,
        collaborators: action.success ? action.data : [],
      };
    default:
      return state;
  }
};
