import { useState } from "react";
import { useSelector } from "react-redux";
import {
  InputText,
  Button,
  InputCheckbox,
  Flex,
} from "@sahadevia/ui-kit-modern-sahadevia";
import classNames from "classnames";

import { useAppDispatch } from "../../reducers";

import { signin } from "../../actions/authAction";

import styles from "../../styles/auth/Signin.module.css";
import globalStyles from "../../styles/global.module.css";

import ForgotPassword from "./ForgotPassword";

type SigninProps = {
  transform: boolean;
  isMobile?: boolean;
};

export default function Signin({ transform, isMobile }: SigninProps) {
  const dispatch = useAppDispatch();

  let { isError } = useSelector((state: any) => state.authReducer);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isRemember, seIsRemember] = useState<boolean>(false);
  const [isErrorInput, setIsErrorInput] = useState<boolean>(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const _signin = () => {
    if (email && password) {
      dispatch(signin(email, password, isRemember));
    } else {
      setIsErrorInput(true);
    }
  };

  return (
    <div
      className={classNames(styles.signin_main_container, {
        [styles.signin_main_container_opacity]: !transform,
        [styles.signin_main_container_mobile]: isMobile,
      })}
    >
      {!showForgotPassword ? (
        <div
          className={classNames(styles.signin_container, {
            [styles.signin_container_mobile]: isMobile,
          })}
        >
          <h1
            className={classNames(globalStyles.global_title, {
              [globalStyles.global_title_mobile]: isMobile,
            })}
          >
            Connexion
          </h1>
          <p
            className={classNames(styles.signin_description, {
              [styles.signin_description_mobile]: isMobile,
            })}
          >
            Bienvenue de retour sur Mon parc immobilier, veuillez vous connecter
            pour accéder à votre espace personnel.
          </p>
          <InputText
            error={isErrorInput || isError}
            iconLeft={{ name: "Envelope" }}
            iconRight={
              (isErrorInput || isError) && email
                ? {
                    name: "Cross",
                    size: 18,
                    onClick: () => setEmail(""),
                  }
                : undefined
            }
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={({ target }: { target: { value: string } }) => {
              if (isErrorInput || isError) {
                dispatch({ type: "RESET_IS_ERROR" });
                setIsErrorInput(false);
              }
              setEmail(target.value);
            }}
          />
          <InputText
            error={isErrorInput || isError}
            iconLeft={{ name: "Lock" }}
            iconRight={
              (isErrorInput || isError) && password
                ? {
                    name: "Cross",
                    size: 18,
                    onClick: () => setPassword(""),
                  }
                : undefined
            }
            type={showPassword ? "text" : "password"}
            placeholder="Mot de passe"
            value={password}
            onChange={({ target }: { target: { value: string } }) => {
              if (isErrorInput || isError) {
                dispatch({ type: "RESET_IS_ERROR" });
                setIsErrorInput(false);
              }
              setPassword(target.value);
            }}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                _signin();
              }
            }}
          />
          <div style={{ display: "flex", gap: 10 }}>
            {(!email || !password) && isErrorInput ? (
              <p
                className={globalStyles.global_text_error}
                style={{ margin: "auto", marginLeft: 0 }}
              >
                Veuillez remplir tous champs
              </p>
            ) : null}
            {isError ? (
              <p
                className={globalStyles.global_text_error}
                style={{ margin: "auto", marginLeft: 0 }}
              >
                Mot de passe ou identifiant incorrect
              </p>
            ) : null}
          </div>
          <Flex justify="space-between">
            <InputCheckbox
              text={"Se souvenir de moi"}
              isCheck={isRemember}
              onClick={() => seIsRemember(!isRemember)}
              onChange={() => {}}
            />
            <p
              className={styles.signin_forgot_password_text}
              onClick={() => setShowForgotPassword(true)}
            >
              Mot de passe oublié
            </p>
          </Flex>
          <Button
            style={{ margin: "auto" }}
            text="Se connecter"
            onClick={() => _signin()}
          />
        </div>
      ) : (
        <ForgotPassword
          setShowForgotPassword={setShowForgotPassword}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}
