import { Col } from "react-flexbox-grid";
import { TCollaborator } from "../../../types/collaborators/collaboratorsType";

type Props = {
  collaborator: TCollaborator;
};

export default function CollaboratorCard(props: Props) {
  const { collaborator } = props;

  return (
    <Col xs={12} md={6} lg={3}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
          border: "0.7px solid var(--Gris-gla, #C7C7C7)",
          background: "#FFF",
          padding: 20,
          position: "relative",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            color: "#3C3C3C",
            fontWeight: 600,
          }}
        >
          {collaborator.name}
        </span>
        <br />
        <span
          style={{
            fontSize: "15px",
            color: "#3C3C3C",
          }}
        >
          {collaborator.email}
        </span>
        <span
          style={{
            fontSize: "15px",
            color: `${collaborator.phone ? "#3C3C3C" : "grey"}`,
          }}
        >
          {collaborator.phone ? collaborator.phone : "Numéro non défini"}
        </span>
        <br />
        <span
          style={{
            fontSize: "15px",
            color: "var(--primary-bg-color)",
          }}
        >
          {collaborator.role}
        </span>
      </div>
    </Col>
  );
}
