import { Card } from "@sahadevia/ui-kit-modern-sahadevia";

function Calendar() {
  return (
    <Card title="Calendrier" className="h-100">
      <></>
    </Card>
  );
}

export default Calendar;
