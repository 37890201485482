export const initialState = {
  tenants: <any>[],
};

export const tenantReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET-TENANTS":
      return {
        ...state,
        tenants: action.success ? action.data : [],
      };
    case "ADD-TENANT":
      let tenants = state.tenants;

      if (action.success) {
        tenants.push(action.data);
      }

      return {
        ...state,
        tenants,
      };
    default:
      return state;
  }
};
