import Card from "./general/Card";

type LineProps = {
  title: string;
  date: string;
  amount: number;
};

function Line({ title, date, amount }: LineProps) {
  return (
    <div
      className="d-flex flex-column p-10 cursor-pointer"
      style={{
        borderRadius: 8,
        backgroundColor: "#FFFEFB",
        boxShadow: "2px 4px 11px 0px rgba(41, 41, 41, 0.05)",
      }}
    >
      <span>
        {title} - {date}
      </span>
      <span
        style={{
          fontSize: 15,
          color: "#0c8540",
        }}
      >
        {amount}
      </span>
    </div>
  );
}

type PaymentsProps = {
  data: Array<any>;
};

function Payments({ data }: PaymentsProps) {
  return (
    <Card title="Paiements">
      <div className="p-20 pt-0 pb-O">
        <h3
          style={{
            color: "#3C3C3C",
            fontSize: 18,
          }}
        >
          Solde : <span style={{ color: "#db2828" }}>- 100,00 €</span>
        </h3>
        <div
          className="d-flex flex-column"
          style={{
            gap: 20,
          }}
        >
          {data.length ? (
            <>
              {data.map((payment, idx) => (
                <Line
                  key={idx}
                  title={payment.title}
                  date={payment.date}
                  amount={payment.amount}
                />
              ))}
            </>
          ) : (
            <span
              className="m-auto"
              style={{
                color: "#ADADAD",
                textAlign: "center",
              }}
            >
              Aucun paiement
            </span>
          )}
        </div>
      </div>
    </Card>
  );
}

export default Payments;
