import { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Button,
  Flex,
  InputText,
  Modal,
} from "@sahadevia/ui-kit-modern-sahadevia";

import { useAppDispatch } from "../../../reducers";

import { addTenant } from "../../../actions/tenantAction";

import { InputChange } from "../../../types/general/generalType";

interface ModalTenantProps {
  onClose: () => void;
}

function ModalTenant({ onClose }: ModalTenantProps) {
  const dispatch = useAppDispatch();

  const [kind, setKind] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const _addTenant = () => {
    if (kind && name && surname && birthday && email && phone) {
      dispatch(
        addTenant(kind, name, surname, birthday, email, phone, () => {
          onClose();
        })
      );
    } else {
      alert("Veuillez remplir tous les champs obligatoires");
    }
  };

  return (
    <Modal target="root">
      <Flex
        classNames="shadow"
        onClick={(e) => e?.stopPropagation()}
        direction="column"
        styles={{
          background: "var(--white)",
          border: "1px solid var(--light-grey)",
          padding: 25,
          borderRadius: 8,
          width: 900,
          maxHeight: "calc(100% - 40px)",
          maxWidth: "calc(100% - 40px)",
          overflowY: "auto",
          gap: 20,
        }}
      >
        <h3 className="m-0">Ajouter un logement</h3>
        <Row style={{ rowGap: 16 }}>
          <Col xs={12} md={6} lg={3}>
            <InputText
              label="Genre"
              placeholder=""
              value={kind}
              onChange={({ target }: InputChange) => setKind(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={5}>
            <InputText
              label="Prénom"
              placeholder=""
              value={name}
              onChange={({ target }: InputChange) => setName(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <InputText
              label="Nom"
              placeholder=""
              value={surname}
              onChange={({ target }: InputChange) => setSurname(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <InputText
              type="date"
              label="Date de naissance"
              placeholder=""
              value={birthday}
              onChange={({ target }: InputChange) => setBirthday(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <InputText
              type="email"
              label="Adresse E-mail"
              placeholder=""
              value={email}
              onChange={({ target }: InputChange) => setEmail(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <InputText
              type="phone"
              label="Téléphone portable"
              placeholder=""
              value={phone}
              onChange={({ target }: InputChange) => setPhone(target.value)}
            />
          </Col>
        </Row>
        <div style={{ display: "flex", gap: 10, justifyContent: "end" }}>
          <Button text="Annuler" outline onClick={() => onClose()} />
          <Button text="Valider" onClick={() => _addTenant()} />
        </div>
      </Flex>
    </Modal>
  );
}

export default ModalTenant;
