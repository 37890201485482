import moment from "moment";
import {
  faFileAlt,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

const generateString = (length = 28) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const getNumber = (int: number) => {
  return new Intl.NumberFormat("fr-FR").format(int);
};

const getDate = (date: string) => {
  if (!date || date === "0000-00-00") {
    return null;
  }

  return moment(date).format("DD/MM/YYYY");
};

const getCurrencyValue = (value: null | number, digits = 2) => {
  if (value == null) {
    return "";
  }

  const optionsCurrency = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsCurrency).format(value);
};

const getPercentageValue = (value: null | number, digits = 2) => {
  if (value == null) {
    return "";
  }

  const optionsPercentage = {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsPercentage).format(value);
};

const getIconFile = (extension: string) => {
  extension = extension ? extension : "";

  if (extension.includes("pdf")) {
    return [faFilePdf, "red"];
  } else if (extension.includes("doc") || extension.includes("docx")) {
    return [faFileWord, "blue"];
  } else if (extension.includes("xls") || extension.includes("xlsx")) {
    return [faFileExcel, "green"];
  } else if (
    extension.includes("png") ||
    extension.includes("jpg") ||
    extension.includes("jpeg")
  ) {
    return [faImage, "purple"];
  } else {
    return [faFileAlt, "orange"];
  }
};

const downloadFile = (path: string, filename: string) => {
  let link = document.createElement("a");
  link.setAttribute("download", filename);
  link.href = path;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getUrl = () => {
  if (window.location.port === "3000") {
    return "http://localhost:5000";
  } else {
    return "https://mon-parc-immobilier.sahadevia.fr";
  }
};

const getFromValue = (
  data: any,
  name: string,
  value: number | string | boolean
) => {
  const result = data.filter((element: any) => element[name] === value)[0];

  if (result) {
    return result;
  } else {
    return {};
  }
};

export {
  generateString,
  getNumber,
  getDate,
  getCurrencyValue,
  getPercentageValue,
  getIconFile,
  downloadFile,
  getUrl,
  getFromValue,
};
