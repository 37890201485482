import { useEffect, useState } from "react";
import { Button, InputText } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { useAppDispatch } from "../../reducers";

import { signup } from "../../actions/authAction";

import styles from "../../styles/auth/Signup.module.css";
import globalStyles from "../../styles/global.module.css";

type Props = {
  transform: boolean;
  isMobile?: boolean;
};

const SignUp = ({ transform, isMobile }: Props) => {
  const dispatch = useAppDispatch();

  let { isEmailExist, isSignup } = useSelector(
    (state: any) => state.authReducer
  );

  useEffect(() => {
    if (isSignup) {
      document.location.reload();
    }
  }, [isSignup]);

  const [companyName, setCompanyName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isErrorInput, setIsErrorInput] = useState<boolean>(false);

  const _signup = () => {
    if (email && password) {
      dispatch(signup(companyName, name, email, password));
    } else {
      setIsErrorInput(true);
    }
  };

  return (
    <div
      className={classNames(styles.signup_main_container, {
        [styles.signup_main_container_opacity]: transform,
        [styles.signup_main_container_mobile]: isMobile,
      })}
    >
      <div
        className={classNames(styles.signup_container, {
          [styles.signup_container_mobile]: isMobile,
        })}
      >
        <h1
          className={classNames(globalStyles.global_title, {
            [globalStyles.global_title_mobile]: isMobile,
          })}
        >
          Inscription
        </h1>
        <p
          className={classNames(styles.signup_description, {
            [styles.signup_description_mobile]: isMobile,
          })}
        >
          L'inscription est simple et rapide. Il vous suffit de remplir le
          formulaire ci-dessous pour créer votre compte personnel.
        </p>
        <InputText
          iconLeft={{ name: "House" }}
          placeholder="Organisation"
          value={companyName}
          onChange={({ target }) => setCompanyName(target.value)}
        />
        <InputText
          iconLeft={{ name: "User" }}
          placeholder="Nom"
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
        <InputText
          error={isEmailExist}
          iconLeft={{ name: "Envelope" }}
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
        <InputText
          iconLeft={{ name: "Lock" }}
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
        <div style={{ display: "flex", gap: 10 }}>
          {(!email || !password) && isErrorInput ? (
            <p
              className={globalStyles.global_text_error}
              style={{ margin: "auto", marginLeft: 0 }}
            >
              Veuillez remplir tous champs
            </p>
          ) : null}
        </div>
        <Button
          style={{ margin: "auto" }}
          text="S’inscrire"
          onClick={() => _signup()}
        />
      </div>
    </div>
  );
};

export default SignUp;
