import { useEffect, useState, Fragment } from "react";
import {
  Button,
  Flex,
  InputCheckbox,
  InputDate,
  InputSelect,
  InputText,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { getEditor, getPdf, updateEditor } from "../../actions/editorAction";

import templates, {
  TTemplateEditors,
  TTemplateEditorsBaseElements,
} from "../../data/template-editors";
import { Row, Col } from "react-flexbox-grid";
import DayMonthSelect from "../../components/general/DayMonthSelect";

const types: any = {
  bail: { name: "Bail", file: "" },
  "etat-des-lieux-entree": { name: "État des lieux d'entrée", file: "" },
  "etat-des-lieux-sortie": { name: "État des lieux de sortie", file: "" },
};

function Editor() {
  const dispatch = useAppDispatch();
  let { type, token } = useParams();

  const { editors } = useSelector((state: any) => state.editorReducer);

  useEffect(() => {
    if (token) {
      dispatch(getEditor(token));
    }
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  if (
    !type ||
    !token ||
    !types.hasOwnProperty(type) ||
    !templates.hasOwnProperty(type)
  ) {
    return <p>Erreur !</p>;
  }

  const dataPages = templates[type as keyof TTemplateEditors].body;
  const dataPage = dataPages[currentPage];

  const idx = editors.findIndex((element: any) => element.token === token);
  const editor = editors[idx];

  if (!editor || !editor.data) {
    return <p>Chargement en cours...</p>;
  }

  const getElement = (data: TTemplateEditorsBaseElements) => {
    const { type, name, title, texts, condition } = data;

    if (type === "Radio") {
      return (
        <Flex display="flex" direction="column" gap={5}>
          {title ? <p>{title}</p> : ""}
          {texts?.map((text, idx) => (
            <InputCheckbox
              text={text}
              isCheck={editor.data[name] === text || false}
              onChange={(isCheck) => {
                const tmpEditor = JSON.parse(JSON.stringify(editor));

                tmpEditor.data[name] = isCheck ? text : "";
                dispatch({
                  type: "UPDATE-EDITOR",
                  body: { editorToken: token, data: tmpEditor.data },
                });
              }}
              onClick={() => {}}
              key={idx}
            />
          ))}
        </Flex>
      );
    } else if (type === "Checkbox") {
      return (
        <Flex display="flex" direction="column" gap={5}>
          {title ? <p>{title}</p> : ""}
          {texts?.map((text, idx) => (
            <Fragment key={idx}>
              <InputCheckbox
                text={text}
                isCheck={editor.data[name]?.includes(text) || false}
                onChange={(isCheck) => {
                  const tmpEditor = JSON.parse(JSON.stringify(editor));

                  if (isCheck) {
                    tmpEditor.data[name] = [
                      ...(tmpEditor.data[name] || []),
                      text,
                    ];
                  } else {
                    tmpEditor.data[name] = (tmpEditor.data[name] || []).filter(
                      (item: string) => item !== text
                    );
                  }
                  dispatch({
                    type: "UPDATE-EDITOR",
                    body: { editorToken: token, data: tmpEditor.data },
                  });
                }}
                onClick={() => {}}
                key={idx}
              />
              {editor.data[name]?.includes(text) && (
                <InputText
                  label={`Texte associé à ${text}`}
                  placeholder={`Saisissez le texte associé à ${text}`}
                  value={editor.data[`text_${name}_${text}`] || ""}
                  onChange={({ target }) => {
                    editor.data[`text_${name}_${text}`] = target.value;
                    dispatch({
                      type: "UPDATE-EDITOR",
                      body: { editorToken: token, data: editor.data },
                    });
                  }}
                />
              )}
            </Fragment>
          ))}
        </Flex>
      );
    } else if (
      type === "InputText" &&
      title &&
      (!data.hasOwnProperty("condition") ||
        condition?.value.includes(editor.data[condition?.key || ""]))
    ) {
      return (
        <InputText
          label={title}
          placeholder={title}
          value={editor.data[name] || ""}
          onChange={({ target }) => {
            const tmpEditor = JSON.parse(JSON.stringify(editor));
            tmpEditor.data[name] = target.value;
            dispatch({
              type: "UPDATE-EDITOR",
              body: { editorToken: token, data: tmpEditor.data },
            });
          }}
        />
      );
    } else if (
      type === "InputDate" &&
      title &&
      (!data.hasOwnProperty("condition") ||
        condition?.value.includes(editor.data[condition?.key || ""]))
    ) {
      return (
        <InputDate
          label={title}
          value={editor.data[name] || ""}
          onChange={({ target }) => {
            editor.data[name] = target.value;
            dispatch({
              type: "UPDATE-EDITOR",
              body: { editorToken: token, data: editor.data },
            });
          }}
        />
      );
    } else if (
      type === "InputSelect" &&
      title &&
      texts &&
      (!data.hasOwnProperty("condition") ||
        condition?.value.includes(editor.data[condition?.key || ""]))
    ) {
      return (
        <InputSelect
          placeholder={title}
          label={title}
          options={texts?.map((text) => ({
            value: text,
            text: text,
          }))}
          value={editor.data[name] || ""}
          onChange={(value) => {
            const tmpEditor = JSON.parse(JSON.stringify(editor));
            tmpEditor.data[name] = value;
            dispatch({
              type: "UPDATE-EDITOR",
              body: { editorToken: token, data: tmpEditor.data },
            });
          }}
        />
      );
    } else if (
      type === "DayMonthSelect" &&
      (!data.hasOwnProperty("condition") ||
        condition?.value.includes(editor.data[condition?.key || ""]))
    ) {
      return (
        <DayMonthSelect
          value={editor.data[name]}
          label={title}
          onChange={(value) => {
            const tmpEditor = JSON.parse(JSON.stringify(editor));
            tmpEditor.data[name] = value;

            dispatch({
              type: "UPDATE-EDITOR",
              body: { editorToken: token, data: tmpEditor.data },
            });
          }}
        />
      );
    } else {
      return null;
    }
  };

  const getHtmlTemplate = () => {
    return (
      <div id="container_editor_template">
        <div style={{ fontFamily: "sans-serif" }}>
          <div>
            <h1>Contrat de location</h1>
            {editor.data["rentType"] ? (
              <>
                {editor.data["rentType"] === "Une location vide" ? (
                  <h2>Logement NU</h2>
                ) : (
                  <h2>Logement MEUBLE</h2>
                )}
              </>
            ) : null}
            <h3>
              Soumis au titre Ier de la loi du 6 juillet 1989 tendant à
              améliorer les rapports locatifs et portant modification de la loi
              n° 86-1290 du 23 décembre 1986
            </h3>
          </div>

          <h1>I. DESIGNATION DES PARTIES</h1>
          <p>Le présent contrat est conclu entre les soussignés :</p>
          <ul>
            <li>
              {editor.data["ownerName"]}, {editor.data["ownerAddress"]},{" "}
              {editor.data["ownerZipCode"]} {editor.data["ownerCity"]},{" "}
              {editor.data["ownerType"] === "Le bailleur est un particulier"
                ? "personne physique"
                : "personne morale"}{" "}
              désigné ci-après le bailleur
            </li>
            {/* <li>Le cas échéant, représenté par le mandataire :</li>
            <li>
              [nom ou raison sociale et adresse du mandataire ainsi que
              l'activité exercée] ;
            </li>
            <li>
              Le cas échéant, [numéro et lieu de délivrance de la carte
              professionnelle/ nom et adresse du garant] (3).
            </li> */}
            <li>
              {editor.data["tenantSurname"]} {editor.data["tenantName"]}{" "}
              ci-après le locataire
            </li>
          </ul>

          <h1>II. OBJET DU CONTRAT</h1>
          <p>
            Le présent contrat a pour objet la location d'un logement ainsi
            déterminé :
          </p>
          <ol>
            <li>
              <strong>A. Consistance du logement</strong>
              <ul>
                <li>
                  Localisation du logement : {editor.data["housingAddress1"]}{" "}
                  {editor.data["housingAddress2"]},{" "}
                  {editor.data["housingZipCode"]}{" "}
                  {editor.data["housingAdhousingCitydress1"]}
                </li>
                <li>Type d'habitat : {editor.data["housingType"]}</li>
                {editor.data["housingType2"] === "Oui" ? (
                  <>
                    <li>
                      Régime juridique de l'immeuble :
                      {editor.data["housingType3"]}
                    </li>
                  </>
                ) : null}
                <li>Période de construction : {editor.data["housingYear"]}</li>
                <li>surface habitable : {editor.data["housingSize"]} m2</li>
                <li>
                  nombre de pièces principales : {editor.data["housingRoom"]}
                </li>
                <li>
                  le cas échéant, Autres parties du logement : [exemples :
                  grenier, comble aménagé ou non, terrasse, balcon,
                  loggia,jardin etc.] ;
                </li>
                <li>
                  le cas échéant, Eléments d'équipements du logement : [exemples
                  : cuisine équipée, détail des installations sanitaires etc.] ;
                </li>
                <li>
                  modalité de production de chauffage :{" "}
                  {editor.data["heatingIndividual"]}
                </li>
                <li>
                  modalité de production d'eau chaude sanitaire :{" "}
                  {editor.data["hotWaterIndividual"]}
                </li>
              </ul>
            </li>
            <li>
              <strong>B. Destination des locaux</strong> :{" "}
              {editor.data["housingUsage"]}
            </li>
            <li>
              <strong>
                C. Le cas échéant, Désignation des locaux et équipements
                accessoires de l'immeuble à usage privatif du locataire
              </strong>{" "}
              : [exemples : cave, parking, garage etc.]
            </li>
            <li>
              <strong>
                D. Le cas échéant, Enumération des locaux, parties, équipements
                et accessoires de l'immeuble à usage commun
              </strong>{" "}
              : [Garage à vélo, ascenseur, espaces verts, aires et équipements
              de jeux, laverie, local poubelle, gardiennage, autres prestations
              et services collectifs etc.]
            </li>
            <li>
              <strong>
                E. Le cas échéant, Equipement d'accès aux technologies de
                l'information et de la communication
              </strong>{" "}
              : [exemples : modalités de réception de la télévision dans
              l'immeuble, modalités de raccordement internet etc.]
            </li>
          </ol>

          <h1>III. DATE DE PRISE D'EFFET ET DUREE DU CONTRAT</h1>
          <p>
            La durée du contrat et sa date de prise d'effet sont ainsi définies
            :
          </p>
          <ol>
            <li>
              <strong>A. Date de prise d'effet du contrat</strong> :{" "}
              {editor.data["dateStartRent"]}
            </li>
            <li>
              <strong>B. Durée du contrat</strong> : {editor.data["duringRent"]}
            </li>
            <li>
              <strong>
                C. Le cas échéant, Evénement et raison justifiant la durée
                réduite du contrat de location
              </strong>{" "}
              : [...]
            </li>
          </ol>

          <h1>IV. CONDITIONS FINANCIERES</h1>
          <p>Les parties conviennent des conditions financières suivantes :</p>
          <ol>
            <li>
              <strong>A. Loyer</strong>
              <ol>
                <li>
                  1° <strong>Fixation du loyer initial</strong> :
                  <ul>
                    <li>a) Montant du loyer mensuel : {editor.data["rent"]}</li>
                    <li>
                      b) Le cas échant, Modalités particulières de fixation
                      initiale du loyer applicables dans certaines zones tendues
                      (8) :
                    </li>
                    <li>
                      le loyer du logement objet du présent contrat est soumis
                      au décret fixant annuellement le montant maximum
                      d'évolution des loyers à la relocation : [Oui/ Non].
                    </li>
                    <li>
                      le loyer du logement objet du présent contrat est soumis
                      au loyer de référence majoré fixé par arrêté préfectoral :
                      [Oui/ Non].
                    </li>
                    <li>
                      montant du loyer de référence : [...] €/ m2/ Montant du
                      loyer de référence majoré : [...] €/ m2 ;
                    </li>
                    <li>
                      le cas échéant Complément de loyer : [si un complément de
                      loyer est prévu, indiquer le montant du loyer de base,
                      nécessairement égal au loyer de référence majoré, le
                      montant du complément de loyer et les caractéristiques du
                      logement justifiant le complément de loyer].
                    </li>
                    <li>
                      c) Le cas échéant, informations relatives au loyer du
                      dernier locataire : [montant du dernier loyer acquitté par
                      le précédent locataire, date de versement et date de la{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  2° <strong>Le cas échéant, Modalités de révision</strong> :
                  <ul>
                    <li>a) Date de révision : {editor.data["rentReview"]}</li>
                    <li>b) Date ou trimestre de référence de l'IRL : [...].</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <strong>B. Charges récupérables</strong>
              <ol>
                <li>
                  1.{" "}
                  <strong>
                    Modalité de règlement des charges récupérables
                  </strong>{" "}
                  : {editor.data["typeCharge"]}
                </li>
                <li>
                  2. Le cas échéant, Montant des provisions sur charges ou, en
                  cas de colocation, du forfait de charge :{" "}
                  {editor.data["charge"]}.
                </li>
                <li>
                  3. Le cas échéant, En cas de colocation et si les parties en
                  conviennent, modalités de révision du forfait de charges :
                  [...] (10).
                </li>
              </ol>
            </li>
            <li>
              <strong>
                C. Le cas échéant, contribution pour le partage des économies de
                charges
              </strong>{" "}
              : (11)
              <ol>
                <li>
                  1.{" "}
                  <strong>
                    Montant et durée de la participation du locataire restant à
                    courir au jour de la signature du contrat
                  </strong>{" "}
                  : [...].
                </li>
                <li>
                  2. Eléments propres à justifier les travaux réalisés donnant
                  lieu à cette contribution : [...].
                </li>
              </ol>
            </li>
            <li>
              <strong>
                D. Le cas échéant, En cas de colocation souscription par le
                bailleur d'une assurance pour le compte des colocataires (12)
              </strong>{" "}
              : [Oui/ Non]
              <ol>
                <li>
                  1.{" "}
                  <strong>
                    Montant total annuel récupérable au titre de l'assurance
                    pour compte des colocataires
                  </strong>{" "}
                  : [...] (13).
                </li>
                <li>2. Montant récupérable par douzième : [...].</li>
              </ol>
            </li>
            <li>
              <strong>E. Modalités de paiement</strong>
              <ul>
                <li>- périodicité du paiement : [... (14)] ;</li>
                <li>- paiement [à échoir/ à terme échu] ;</li>
                <li>- date ou période de paiement : [...] ;</li>
                <li>- le cas échéant, Lieu de paiement : [...] ;</li>
                <li>
                  - le cas échéant, Montant total dû à la première échéance de
                  paiement pour une période complète de location : [détailler la
                  somme des montants relatifs au loyer, aux charges récupérable,
                  à la contribution pour le partage des économies de charges et,
                  en cas de colocation, à l'assurance récupérable pou le compte
                  des colocataires].
                </li>
              </ul>
            </li>
            <li>
              <strong>
                F. Le cas échéant, exclusivement lors d'un renouvellement de
                contrat, modalités de réévaluation d'un loyer manifestement
                sous-évalué
              </strong>
              <ol>
                <li>
                  1.{" "}
                  <strong>
                    Montant de la hausse ou de la baisse de loyer mensuelle
                  </strong>{" "}
                  : [...].
                </li>
                <li>
                  2. Modalité d'application annuelle de la hausse : [par tiers
                  ou par sixième selon la durée du contrat et le montant de la
                  hausse de loyer].
                </li>
              </ol>
            </li>
          </ol>
          <h1>V. TRAVAUX</h1>
          <p>
            Les parties conviennent des conditions relatives aux travaux :{" "}
            {editor.data["works"]}
          </p>
          {editor.data["works"] === "Oui" ? (
            <ol>
              <li>
                <strong>
                  A. Le cas échéant, Montant et nature des travaux
                  d'amélioration ou de mise en conformité avec les
                  caractéristiques de décence effectués depuis la fin du dernier
                  contrat de location ou depuis le dernier renouvellement
                </strong>{" "}
                : [...]
              </li>
              <li>
                <strong>
                  B. Le cas échéant, Majoration du loyer en cours de bail
                  consécutive à des travaux d'amélioration entrepris par le
                  bailleur
                </strong>{" "}
                : [...]
              </li>
              <li>
                <strong>
                  C. Le cas échéant, Diminution de loyer en cours de bail
                  consécutive à des travaux entrepris par le locataire
                </strong>{" "}
                : [...]
              </li>
            </ol>
          ) : null}
          <h1>VI. GARANTIES</h1>
          <p>
            Le cas échéant, les parties conviennent des garanties suivantes :
          </p>
          <ol>
            <li>
              <strong>
                A. Montant du dépôt de garantie de l'exécution des obligations
                du locataire/ Garantie autonome
              </strong>{" "}
              : {editor.data["warranty"]}
            </li>
          </ol>

          <h1>VII. LE CAS ECHEANT, CLAUSE DE SOLIDARITE</h1>
          <p>
            Modalités particulières des obligations en cas de pluralité de
            locataires :
          </p>
          <ul>
            <li>
              <strong>
                Clause prévoyant la solidarité des locataires et
                l'indivisibilité de leurs obligations en cas de pluralité de
                locataires.
              </strong>
            </li>
          </ul>

          <h1>VIII. LE CAS ECHEANT, CLAUSE RESOLUTOIRE</h1>
          <p>Modalités de résiliation de plein droit du contrat :</p>
          <ul>
            <li>
              <strong>
                Clause prévoyant la résiliation de plein droit du contrat de
                location pour un défaut de paiement du loyer ou des charges aux
                termes convenus, le non-versement du dépôt de garantie, la
                non-souscription d'une assurance des risques locatifs ou le
                non-respect de l'obligation d'user paisiblement des locaux
                loués, résultant de troubles de voisinage constatés par une
                décision de justice passée en force de chose jugée
              </strong>
              .
            </li>
          </ul>

          {editor.data["rentalFees"] ? (
            <>
              <h1>IX. LE CAS ECHEANT, HONORAIRES DE LOCATION (17)</h1>
              <p>
                Dispositions applicables en matière d'honoraires de location :
              </p>
              <ol>
                <li>
                  <strong>A. Dispositions applicables</strong>
                  <ul>
                    <li>
                      Il est rappelé les dispositions du I de l'article 5 (I) de
                      la loi du 6 juillet 1989, alinéas 1 à 3 :
                    </li>
                    <li>
                      La rémunération des personnes mandatées pour se livrer ou
                      prêter leur concours à l'entremise ou à la négociation
                      d'une mise en location d'un logement, tel que défini aux
                      articles 2 et 25-3, est à la charge exclusive du bailleur,
                      à l'exception des honoraires liés aux prestations
                      mentionnées aux deuxième et troisième alinéas du présent
                      I.
                    </li>
                    <li>
                      Les honoraires des personnes mandatées pour effectuer la
                      visite du preneur, constituer son dossier et rédiger un
                      bail sont partagés entre le bailleur et le preneur. Le
                      montant toutes taxes comprises imputé au preneur pour ces
                      prestations ne peut excéder celui imputé au bailleur et
                      demeure inférieur ou égal à un plafond par mètre carré de
                      surface habitable de la chose louée fixé par voie
                      réglementaire et révisable chaque année, dans des
                      conditions définies par décret. Ces honoraires sont dus à
                      la signature du bail.
                    </li>
                    <li>
                      Les honoraires des personnes mandatées pour réaliser un
                      état des lieux sont partagés entre le bailleur et le
                      preneur. Le montant toutes taxes comprises imputé au
                      locataire pour cette prestation ne peut excéder celui
                      imputé au bailleur et demeure inférieur ou égal à un
                      plafond par mètre carré de surface habitable de la chose
                      louée fixé par voie réglementaire et révisable chaque
                      année, dans des conditions définies par décret. Ces
                      honoraires sont dus à compter de la réalisation de la
                      prestation.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>B. Plafonds applicables</strong>
                  <ul>
                    <li>
                      Montant du plafond des honoraires imputables aux
                      locataires en matière de prestation de visite du preneur,
                      de constitution de son dossier et de rédaction de bail :
                      [...] €/m2 de surface habitable ;
                    </li>
                    <li>
                      Montant du plafond des honoraires imputables aux
                      locataires en matière d'établissement de l'état des lieux
                      d'entrée : [...] €/m2 de surface habitable.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>C. Détail et répartition des honoraires</strong>
                  <ol>
                    <li>
                      <strong>1. Honoraires à la charge du bailleur</strong> :
                      <ul>
                        <li>
                          Prestations de visite du preneur, de constitution de
                          son dossier et de rédaction de bail : [Détail des
                          prestations effectivement réalisées et montant des
                          honoraires toutes taxes comprises dus à la signature
                          du bail] ;
                        </li>
                        <li>
                          Le cas échéant, Prestation de réalisation de l'état
                          des lieux d'entrée : [Montant des honoraires toutes
                          taxes comprises dus à compter de la réalisation de la
                          prestation] ;
                        </li>
                        <li>
                          Autres prestations : [Détail des prestations et
                          conditions de rémunération].
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>2. Honoraires à la charge du locataire</strong> :
                      <ul>
                        <li>
                          Prestations de visite du preneur, de constitution de
                          son dossier et de rédaction de bail : [Détail des
                          prestations effectivement réalisées et montant des
                          honoraires toutes taxes comprises dus à la signature
                          du bail] ;
                        </li>
                        <li>
                          Le cas échéant, Prestation de réalisation de l'état
                          des lieux d'entrée : [Montant des honoraires toutes
                          taxes comprises dus à compter de la réalisation de la
                          prestation].
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
              </ol>
            </>
          ) : null}
          <h1>X. AUTRES CONDITIONS PARTICULIERES</h1>
          <p>[A définir par les parties]</p>

          <h1>XI. ANNEXES</h1>
          <p>
            Sont annexées et jointes au contrat de location les pièces suivantes
            :
          </p>
          <ol>
            <li>
              <strong>
                A. Le cas échéant, un extrait du règlement concernant la
                destination de l'immeuble, la jouissance et l'usage des parties
                privatives et communes, et précisant la quote-part afférente au
                lot loué dans chacune des catégories de charges
              </strong>
            </li>
            <li>
              <strong>
                B. Un dossier de diagnostic technique comprenant :
              </strong>
              <ul>
                <li>Un diagnostic de performance énergétique ;</li>
                <li>
                  Un constat de risque d'exposition au plomb pour les immeubles
                  construits avant le 1er janvier 1949 ;
                </li>
                <li>
                  Une copie d'un état mentionnant l'absence ou la présence de
                  matériaux ou de produits de la construction contenant de
                  l'amiante (18) ;
                </li>
                <li>
                  Un état de l'installation intérieure d'électricité et de gaz,
                  dont l'objet est d'évaluer les risques pouvant porter atteinte
                  à la sécurité des personnes (19) ;
                </li>
                <li>
                  Le cas échéant, un état des risques naturels et technologiques
                  pour les zones couvertes par un plan de prévention des risques
                  technologiques ou par un plan de prévention des risques
                  naturels prévisibles, prescrit ou approuvé, ou dans des zones
                  de sismicité (20).
                </li>
              </ul>
            </li>
            <li>
              <strong>
                C. Une notice d'information relative aux droits et obligations
                des locataires et des bailleurs
              </strong>
            </li>
            <li>
              <strong>D. Un état des lieux (21)</strong>
            </li>
            <li>
              <strong>
                E. Le cas échéant, Une autorisation préalable de mise en
                location (22)
              </strong>
            </li>
            <li>
              <strong>
                F. Le cas échéant, Les références aux loyers habituellement
                constatés dans le voisinage pour des logements comparables (23)
              </strong>
            </li>
          </ol>

          <p>Le [date], à [lieu],</p>

          <p
            style={{
              display: "inline-block",
              width: "70%",
            }}
          >
            Signature du bailleur [ou de son mandataire, le cas échéant]
          </p>
          <p style={{ display: "inline-block" }}> Signature du locataire</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <h2 className="m-0">
        Édtieur - {type && types.hasOwnProperty(type) ? types[type].name : ""}
      </h2>
      <Row>
        <Col xs={12} lg={6} className="d-flex flex-column" style={{ gap: 10 }}>
          <Row className="d-flex flex-column">
            <h2>
              Page {currentPage + 1}/{dataPages.length} - {dataPage.title}
            </h2>

            {dataPage.elements.map((element, idx: number) => (
              <Flex display="flex" key={idx} gap={10}>
                {getElement(element)}
              </Flex>
            ))}
            <Flex display="flex" gap={10} classNames="mt-10">
              <Button
                text="Retour"
                disabled={!currentPage}
                onClick={() => setCurrentPage(currentPage - 1)}
              />
              <Button
                text="Suivant"
                disabled={currentPage + 1 === dataPages.length}
                onClick={() => setCurrentPage(currentPage + 1)}
              />
              <Button
                text="Sauvegarder"
                onClick={() => dispatch(updateEditor(token, editor.data))}
              />
            </Flex>
          </Row>
          {Object.keys(editor.data).length > 0 && (
            <Row>
              <Button
                text="Télécharger"
                onClick={() => {
                  dispatch(
                    getPdf(
                      token,
                      document.getElementById("container_editor_template")
                        ?.innerHTML,
                      (res) => {
                        if (res.success) {
                          let a = document.createElement("a");
                          a.href = "data:application/pdf;base64," + res.data;
                          a.download = "contrat_location.pdf";
                          a.click();
                        }
                      }
                    )
                  );
                }}
              />
            </Row>
          )}
        </Col>
        <Col></Col>
        <Col xs={12} lg={6}>
          <h2>Prévisualisation</h2>
          {getHtmlTemplate()}
        </Col>
      </Row>
    </>
  );
}

export default Editor;
