import { TBankInfo } from "../types/bank/bankType";

import { _fetch } from "../utils/fetch";

export const getBanks = () => {
  return (dispatch: any) => {
    _fetch("/api/bank/get-banks", "GET-BANKS", JSON.stringify({}), dispatch);
  };
};

export const getConnectLink = (bankId: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/bank/get-connect-link",
      "GET-CONNECT-LINK",
      JSON.stringify({ bankId }),
      dispatch
    );
  };
};

export const getBank = () => {
  return (dispatch: any) => {
    _fetch("/api/bank/get-bank", "GET-BANK", JSON.stringify({}), dispatch);
  };
};

export const updateBank = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/bank/update-bank",
      "UPDATE-BANK",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getBankInfo = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/bank/get-bank-info",
      "GET-BANK-INFO",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const hideBankItem = (hide: number, id: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/bank/hide-bank-item",
      "HIDE-BANK-ITEM",
      JSON.stringify({ id, hide }),
      dispatch
    );
  };
};
