import { useEffect, useState, useRef, ChangeEvent } from "react";
import { Button, Flex, Modal } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import Folder from "./drive/Folder";
import File from "./drive/File";

import { useAppDispatch } from "../../../reducers";

import { Col, Row } from "react-flexbox-grid";

import { getFiles, addFile } from "../../../actions/fileAction";

interface ModalFilesProps {
  onClose: () => void;
  tenantId?: number;
  housingId?: number;
}

function ModalFiles({ onClose, tenantId, housingId }: ModalFilesProps) {
  const dispatch = useAppDispatch();

  const [path, setPath] = useState("");

  useEffect(() => {
    if (tenantId) {
      dispatch(getFiles(tenantId));
    }
  }, []);

  const { files } = useSelector((state: any) => state.fileReducer);

  const folders = [
    {
      name: "AVIS_ECHEANCE",
      title: "Avis d'échénaces",
    },
    {
      name: "QUITTANCE",
      title: "Quittances",
    },
    {
      name: "BAIL",
      title: "Baux",
    },
    {
      name: "INVOICE",
      title: "Factures",
    },
    {
      name: "OTHER",
      title: "Autres",
    },
  ];

  let currentIdx = null;

  if (path) {
    currentIdx = folders.findIndex((element: any) => element.name === path);
  }

  const convertBase64 = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const _addFile = (base64: string, name: string, extension: string) => {
    addFile(base64, path, name, extension, housingId, tenantId);
  };

  const inputFile: React.Ref<any> = useRef(null);

  return (
    <Modal target="root">
      <Flex
        classNames="shadow"
        onClick={(e) => e?.stopPropagation()}
        direction="column"
        styles={{
          background: "var(--white)",
          border: "1px solid var(--light-grey)",
          padding: 25,
          borderRadius: 8,
          width: "calc(100% - 40px)",
          height: "calc(100% - 40px)",
          maxHeight: "calc(100% - 40px)",
          maxWidth: "calc(100% - 40px)",
          overflowY: "auto",
          gap: 20,
        }}
      >
        <h3 className="m-0">Fichiers</h3>
        <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
          <Row style={{ rowGap: 16 }}>
            {!path ? (
              <>
                <Col xs={12}>
                  <h4>Dossiers</h4>
                </Col>
                {folders.map((folder: any, idx: number) => (
                  <Folder
                    key={idx}
                    name={folder.title}
                    onClick={() => setPath(folder.name)}
                  />
                ))}
                <Col xs={12}>
                  <h4>Fichiers récent</h4>
                </Col>
                {files.slice(0, 20).map((file: any, idx: number) => (
                  <File
                    key={idx}
                    name={`${file.name}.${file.extension}`}
                    path={file.path}
                    token={file.token}
                  />
                ))}
              </>
            ) : (
              <>
                <Col xs={12}>
                  <Button text="Retour" outline onClick={() => setPath("")} />
                </Col>
                <Col xs={12}>
                  <h4>Fichiers {folders[currentIdx || 0].title}</h4>
                </Col>
                {files
                  .filter((file: any) => file.type === path)
                  .map((file: any, idx: number) => (
                    <File
                      key={idx}
                      name={`${file.name}.${file.extension}`}
                      path={file.path}
                      token={file.token}
                    />
                  ))}
              </>
            )}
          </Row>
        </div>
        <div style={{ display: "flex", gap: 10, justifyContent: "end" }}>
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={async (event: ChangeEvent<HTMLInputElement>) => {
              if (event.target && event.target.files) {
                const file = event.target.files[0];
                const base64 = await convertBase64(file);

                _addFile(
                  base64,
                  file.name.split(".")[0],
                  file.name.split(".").pop() || ""
                );
              }
            }}
          />
          <Button
            disabled={!path || path === "AVIS_ECHEANCE" || path === "QUITTANCE"}
            text="Ajouter un fichier"
            outline
            onClick={() => {
              if (inputFile.current) {
                inputFile.current.click();
              }
            }}
          />
          <Button text="Fermer" outline onClick={() => onClose()} />
        </div>
      </Flex>
    </Modal>
  );
}

export default ModalFiles;
