import { Flex } from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";

import styles from "../styles/layout/generalLayout.module.css";

import SmallLogo from "../images/icons/SmallLogo";

type Props = {
  children: JSX.Element;
};
export default function GeneralLayout({ children }: Props) {
  const navigate = useNavigate();

  return (
    <Flex
      justify="center"
      align="center"
      classNames={styles.general_main_container}
    >
      <div className={styles.general_left_top} onClick={() => navigate("/")}>
        <SmallLogo size={40} />
      </div>
      {children}
    </Flex>
  );
}
