import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import moment from "moment";

import { getCurrencyValue, getPercentageValue } from "../../../utils/general";

function Income({ isYear = false }) {
  const { transactions } = useSelector((state: any) => state.bankReducer);

  let income = 0;
  let oldIncome = 0;

  transactions
    .filter((transaction: any) => !transaction.hide)
    .forEach((transaction: any) => {
      const amount = parseFloat(transaction.amount);

      if (
        (!isYear &&
          moment(transaction.date).format("YYYY-MM") ===
            moment().format("YYYY-MM")) ||
        (isYear &&
          moment(transaction.date).format("YYYY") === moment().format("YYYY"))
      ) {
        if (amount > 0) {
          income += amount;
        }
      } else if (
        (!isYear &&
          moment(transaction.date).format("YYYY-MM") ===
            moment().subtract(1, "months").format("YYYY-MM")) ||
        (isYear &&
          moment(transaction.date).format("YYYY") ===
            moment().subtract(1, "years").format("YYYY"))
      ) {
        if (amount > 0) {
          oldIncome += amount;
        }
      }
    });

  return (
    <Card
      title={`Recettes ${isYear ? "annuelles" : "mensuelles"}`}
      className="h-100"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <p
          className="m-0"
          style={{
            color: "var(--green)",
            fontSize: "46px",
            lineHeight: "140%",
          }}
        >
          {getCurrencyValue(income)}
        </p>
        <div>
          <p
            className="m-0"
            style={{
              color: income / oldIncome - 1 > 0 ? "var(--green)" : "var(--red)",
            }}
          >
            ({getPercentageValue(income / oldIncome - 1)})
          </p>
          <p
            className="m-0"
            style={{
              color: "#C7C7C7",
            }}
          >
            {isYear ? "N par rapport à N-1" : "M par rapport à M-1"}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default Income;
