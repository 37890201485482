import { Flex, InputSelect } from "@sahadevia/ui-kit-modern-sahadevia";
import { useState } from "react";

import moment from "moment";
import "moment/locale/fr";

type Props = {
  onChange: (value: string) => void;
  label?: string;
  value?: string;
};

function DayMonthSelect(props: Props) {
  const { onChange, label, value } = props;

  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentDay, setCurrentDay] = useState(moment().date());
  const [infoText, setInfoText] = useState("");

  const currentYear = moment().year();
  const isLeapYear = moment(currentYear, "YYYY").isLeapYear();

  const daysInMonth = moment(
    `${currentYear}-${currentMonth + 1}`,
    "YYYY-MM"
  ).daysInMonth();

  const days = Array.from({ length: daysInMonth }, (_, index) => ({
    text: (index + 1).toString(),
    value: (index + 1).toString(),
  }));

  const months = moment.months();

  return (
    <Flex direction="column" gap={5}>
      {label}
      <Flex gap={10}>
        <InputSelect
          options={days}
          value={value ? Number(value.split("-")[0]) : currentDay}
          onChange={(v) => {
            const day = Number(v);
            setCurrentDay(day);
            onChange(
              `${day < 10 ? "0" : ""}${day}-${currentMonth < 10 ? "0" : ""}${
                currentMonth + 1
              }-${currentYear}`
            );
          }}
          placeholder={""}
        />
        <InputSelect
          options={months.map((month) => ({
            text: month,
            value: Number(moment().month(month).format("M")) - 1,
          }))}
          value={value ? Number(value.split("-")[1]) - 1 : currentMonth}
          onChange={(v) => {
            const month = Number(v);
            if (month === 1 && isLeapYear && !infoText)
              setInfoText(
                " Année bissextile ! En conséquence, Février comporte 29 jours cette année, au lieu des 28 habituels. Assurez-vous de prendre en compte cette particularité dans votre choix."
              );
            if (month !== 1 && isLeapYear && infoText) setInfoText("");

            setCurrentMonth(month);
            onChange(
              `${currentDay < 10 ? "0" : ""}${currentDay}-${
                month < 10 ? "0" : ""
              }${month + 1}-${currentYear}`
            );
          }}
          placeholder={""}
        />
      </Flex>
      {infoText && (
        <div style={{ fontSize: "10px", color: "red" }}>{infoText}</div>
      )}
    </Flex>
  );
}

export default DayMonthSelect;
