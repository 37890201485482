import { Col, Row } from "react-flexbox-grid";

import Alerts from "../../../components/tenant/desktop/Alerts";
import Messages from "../../../components/tenant/desktop/Messages";
import Cancellation from "../../../components/tenant/desktop/Cancellation";
import Files from "../../../components/tenant/desktop/Files";
import Payments from "../../../components/tenant/desktop/Payments";

function Home() {
  const data = {
    alerts: [
      {
        title: "Travaux RDC",
        date: "2023-06-01",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia ipsam aperiam delectus doloremque vero voluptatibus expedita voluptas qui ad dolore soluta ab laboriosam ex itaque, labore quos nulla quisquam beatae!",
        token: "123",
      },
      {
        title: "Travaux E1",
        date: "2023-06-02",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia ipsam aperiam delectus doloremque vero voluptatibus expedita voluptas qui ad dolore soluta ab laboriosam ex itaque, labore quos nulla quisquam beatae!",
        token: "234",
      },
      {
        title: "Travaux E2",
        date: "2023-06-03",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia ipsam aperiam delectus doloremque vero voluptatibus expedita voluptas qui ad dolore soluta ab laboriosam ex itaque, labore quos nulla quisquam beatae!",
        token: "345",
      },
    ],
    messages: [
      {
        title: "Quittance 05-2023",
        date: "2023-05-25",
        from: "LF SCI",
        message:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis error deserunt sint suscipit sed officia laborum cum minima dolorum asperiores! Ut dolor neque quam repellat. Veniam quaerat tempore dolorum perferendis.",
        token: "123",
      },
      {
        title: "Avis d'échéance 06-2023",
        date: "2023-05-25",
        from: "LF SCI",
        message:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis error deserunt sint suscipit sed officia laborum cum minima dolorum asperiores! Ut dolor neque quam repellat. Veniam quaerat tempore dolorum perferendis.",
        token: "123",
      },
    ],
    files: [
      {
        title: "Quittance-05_2023.pdf",
        date: "2023-06-03",
        token: "123",
      },
      {
        title: "Avis_d_echeance-06_2023.pdf",
        date: "2023-06-03",
        token: "234",
      },
    ],
    payments: [
      {
        title: "Loyer mensuel",
        date: "2023-06-03",
        amount: 200,
        token: "123",
      },
      {
        title: "Loyer mensuel",
        date: "2023-06-03",
        amount: 200,
        token: "234",
      },
    ],
  };

  return (
    <Row style={{ rowGap: 20 }}>
      <Col xs={12} md={12} lg={8}>
        <Alerts data={data.alerts} />
      </Col>
      <Col xs={12} md={12} lg={4}>
        <Messages data={data.messages} />
      </Col>
      <Col xs={12} md={12} lg={5}>
        <Files data={data.files} />
      </Col>
      <Col xs={12} md={12} lg={3}>
        <Cancellation />
      </Col>
      <Col xs={12} md={12} lg={4}>
        <Payments data={data.payments} />
      </Col>
    </Row>
  );
}

export default Home;
