import { _fetch } from "../utils/fetch";
import { TinformationsData } from "../views/dashboard/Profil";

export const updateProfil = (
  data: TinformationsData & { userId: number | null }
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/profil/update-profil",
      "UPDATE-PROFIL",
      JSON.stringify(data),
      dispatch
    );
  };
};
