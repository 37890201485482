import { TBankInfo, TBankInitialState } from "../types/bank/bankType";

export const initialState = {
  banks: [],
  connectLink: "",
  balance: "",
  date: "",
  transactions: [],
  bankInfo: {} as TBankInfo,
} satisfies TBankInitialState;

export const bankReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET-BANKS":
      return {
        ...state,
        banks: action.success ? action.data : [],
      };
    case "GET-CONNECT-LINK":
      return {
        ...state,
        connectLink: action.success ? action.link : "",
      };
    case "GET-BANK":
      return {
        ...state,
        balance: action.success ? action.balance : "",
        date: action.success ? action.date : "",
        transactions: action.success ? action.transactions : [],
      };
    case "UPDATE-BANK":
      return {
        ...state,
        balance: action.success ? action.balance : "",
        date: action.success ? action.date : "",
        transactions: action.success ? action.transactions : [],
      };
    case "GET-BANK-INFO":
      return {
        ...state,
        bankInfo: action.data,
      };
    case "UPDATE-BANK-INFO":
      state.bankInfo = action.data;
      return {
        ...state,
      };
    case "HIDE-BANK-ITEM":
      let tmpTransactions = JSON.parse(JSON.stringify(state.transactions));

      const idx = tmpTransactions.findIndex(
        (element: any) => element.id === action.body.id
      );

      if (idx >= 0) {
        tmpTransactions[idx].hide = action.body.hide;
      }

      return {
        ...state,
        transactions: tmpTransactions,
      };
    default:
      return state;
  }
};
