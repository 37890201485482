import { Folder as FolderIcon } from "@sahadevia/ui-kit-modern-sahadevia";
import { MouseEventHandler } from "react";

import { Col } from "react-flexbox-grid";

interface FolderProps {
  name: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

function Folder({ name, onClick }: FolderProps) {
  return (
    <Col xs={12} md={4} lg={3}>
      <div
        onClick={onClick}
        className="cursor-pointer"
        style={{
          padding: 12,
          borderRadius: 8,
          display: "flex",
          gap: 10,
          backgroundColor: "#F0F4F9",
          color: "#1f1f1f",
        }}
      >
        <div style={{ flexShrink: 0, display: "inline-flex" }}>
          <FolderIcon size={24} color="#1f1f1f" />
        </div>
        <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {name}
        </span>
      </div>
    </Col>
  );
}

export default Folder;
