import {
  Button,
  Flex,
  Table,
  Card,
  Icon,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { getEditors } from "../../actions/editorAction";

import { generateString } from "../../utils/general";
import { useEffect } from "react";

function Editors() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { editors } = useSelector((state: any) => state.editorReducer);

  useEffect(() => {
    dispatch(getEditors());
  }, []);

  return (
    <>
      <h2 className="m-0">Édtieurs</h2>
      <Card title="Créer un document">
        <div className="d-flex gap-10">
          <Button
            style={{ width: "auto" }}
            text="Générer un bail"
            onClick={() => navigate(`bail`)}
          />
          <Button
            style={{ width: "auto" }}
            text="Générer un état des lieux d'entrée"
            onClick={() => navigate(`etat-des-lieux-entree`)}
          />
          <Button
            style={{ width: "auto" }}
            text="Générer un état des lieux de sortie"
            onClick={() => navigate(`etat-des-lieux-sortie`)}
          />
        </div>
      </Card>
      <Table
        title="Liste des documents"
        context={null}
        columns={[
          {
            key: "idx",
            label: "#",
          },
          {
            key: "type",
            label: "Type",
          },
          {
            key: "date",
            label: "Date",
          },
          {
            key: "action",
            label: "Actions",
            component: (line) => (
              <Flex gap={10}>
                <Icon
                  infoTop
                  infoText="Éditer"
                  backgroundHover
                  icon="Pen"
                  size={16}
                  onClick={() => navigate(`${line.type}/${line.token}`)}
                />
              </Flex>
            ),
          },
        ]}
        data={editors.map((editor: any, idx: number) => ({
          ...editor,
          idx,
        }))}
      />
    </>
  );
}

export default Editors;
