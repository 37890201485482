export const initialState = {
  files: <any>[],
  metadataFiles: <any>[],
};

export const fileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET-FILES":
      return {
        ...state,
        files: action.success ? action.data : [],
      };
    case "GET-METADATA-FILES":
      return {
        ...state,
        metadataFiles: action.success ? action.data : [],
      };
    default:
      return state;
  }
};
