import { TEditorInitialState, TEditors } from "../types/editor/editorType";

export const initialState = {
  editors: [] as Array<TEditors>,
} satisfies TEditorInitialState;

export const editorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "UPDATE-EDITOR":
      let tmpEditor: Array<TEditors> = JSON.parse(
        JSON.stringify(state.editors)
      );

      const idx = tmpEditor.findIndex(
        (element: any) => element.token === action.body.editorToken
      );

      if (idx < 0) {
        tmpEditor.push({
          token: action.body.editorToken,
          type: action.body.type || action.data.type,
          data: action.data ? action.data.data : action.body.data || {},
        });
      } else {
        tmpEditor[idx].data = action.data
          ? action.data.data
          : action.body.data || {};
      }

      return {
        ...state,
        editors: tmpEditor,
      };
    case "UPDATE-EDITORS":
      return {
        ...state,
        editors: action.success ? action.data : [],
      };
    default:
      return state;
  }
};
