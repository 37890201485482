import { Col, Row } from "react-flexbox-grid";

import Information from "../../../components/tenant/desktop/Information";
import Security from "../../../components/tenant/desktop/Security";

function User() {
  return (
    <Row style={{ rowGap: 20 }}>
      <Col xs={12}>
        <Information />
      </Col>
      <Col xs={12}>
        <Security />
      </Col>
    </Row>
  );
}

export default User;
