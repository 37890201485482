import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader, Modal } from "@sahadevia/ui-kit-modern-sahadevia";
import { ToastContainer, toast } from "react-toastify";

import Auth from "./layouts/Auth";
import Dashboard from "./layouts/Dashboard";
import TenantDesktop from "./layouts/TenantDesktop";

import Home from "./views/dashboard/Home";
import Statistics from "./views/dashboard/Statistics";
import Tenants from "./views/dashboard/Tenants";
import Bank from "./views/dashboard/Bank";
import Housings from "./views/dashboard/Housings";
import Simulators from "./views/dashboard/Simulators";
import Editors from "./views/dashboard/Editors";
import InsertEditor from "./views/dashboard/InsertEditor";
import Editor from "./views/dashboard/Editor";
import Tutorial from "./views/dashboard/Tutorial";
import Profil from "./views/dashboard/Profil";
import Users from "./views/dashboard/Users";
import Organization from "./views/dashboard/Organization";
import NotFoundView from "./views/NotFoundView";

import TenantDesktopHome from "./views/tenant/desktop/Home";
import TenantDesktopUser from "./views/tenant/desktop/User";
import ResetPassword from "./views/auth/ResetPassword";
import NewCollaborator from "./views/collaborator/NewCollaborator";

import { useAppDispatch } from "./reducers";

import { refreshToken } from "./actions/authAction";
import { getHousings } from "./actions/housingAction";
import { getTenants } from "./actions/tenantAction";
import { getBank } from "./actions/bankAction";

function App() {
  const dispatch = useAppDispatch();

  const { isAuthenticated, userId } = useSelector(
    (state: any) => state.authReducer
  );
  const { isLoad } = useSelector((state: any) => state.loaderReducer);

  useEffect(() => {
    if (!userId) {
      dispatch(refreshToken());
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(refreshToken());
      dispatch(getHousings());
      dispatch(getTenants());
      dispatch(getBank());
    }
  }, [isAuthenticated]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/new-collaborator/:token"
                element={<NewCollaborator />}
              />
              <Route path="*" element={<Auth />} />
            </>
          ) : (
            <>
              <Route
                path="/"
                element={
                  <Dashboard>
                    <Home />
                  </Dashboard>
                }
              />
              <Route
                path="/statistics"
                element={
                  <Dashboard>
                    <Statistics />
                  </Dashboard>
                }
              />
              <Route
                path="/housings"
                element={
                  <Dashboard>
                    <Housings />
                  </Dashboard>
                }
              />
              <Route
                path="/tenants"
                element={
                  <Dashboard>
                    <Tenants />
                  </Dashboard>
                }
              />
              <Route
                path="/bank"
                element={
                  <Dashboard>
                    <Bank />
                  </Dashboard>
                }
              />
              <Route
                path="/simulators"
                element={
                  <Dashboard>
                    <Simulators />
                  </Dashboard>
                }
              />
              <Route
                path="/editors"
                element={
                  <Dashboard>
                    <Editors />
                  </Dashboard>
                }
              />
              <Route
                path="/editors/:type"
                element={
                  <Dashboard>
                    <InsertEditor />
                  </Dashboard>
                }
              />
              <Route
                path="/editors/:type/:token"
                element={
                  <Dashboard>
                    <Editor />
                  </Dashboard>
                }
              />
              <Route
                path="/tutorial"
                element={
                  <Dashboard>
                    <Tutorial />
                  </Dashboard>
                }
              />
              <Route
                path="/profil"
                element={
                  <Dashboard>
                    <Profil />
                  </Dashboard>
                }
              />
              <Route
                path="/users"
                element={
                  <Dashboard>
                    <Users />
                  </Dashboard>
                }
              />
              <Route
                path="/organization"
                element={
                  <Dashboard>
                    <Organization />
                  </Dashboard>
                }
              />
              <Route
                path="*"
                element={
                  <Dashboard>
                    <NotFoundView />
                  </Dashboard>
                }
              />
              <Route
                path="/tenant-desktop-home"
                element={
                  <TenantDesktop>
                    <TenantDesktopHome />
                  </TenantDesktop>
                }
              />
              <Route
                path="/tenant-desktop-user"
                element={
                  <TenantDesktop>
                    <TenantDesktopUser />
                  </TenantDesktop>
                }
              />
            </>
          )}
        </Routes>
      </BrowserRouter>
      {isLoad ? (
        <Modal target="root">
          <Loader />
        </Modal>
      ) : (
        <></>
      )}
      <ToastContainer />
    </>
  );
}

export default App;
