import { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { ResponsiveContainer, AreaChart, Area, Tooltip } from "recharts";
import moment from "moment";
import { useSelector } from "react-redux";

import { getCurrencyValue } from "../../../utils/general";

const setData = (transactions: any) => {
  let totalCashflow = 0;
  let dataMonths: any = Array(12).fill({});

  for (let index = 11; index >= 0; index--) {
    dataMonths[index] = {
      name: moment().subtract(index, "months").format("MM/YYYY"),
      total: 0,
      income: 0,
      outlay: 0,
    };

    transactions
      .filter((transaction: any) => !transaction.hide)
      .forEach((transaction: any) => {
        if (
          moment().subtract(index, "months").format("YYYY-MM") ===
          moment(transaction.date).format("YYYY-MM")
        ) {
          dataMonths[index].total += parseFloat(transaction.amount);
          totalCashflow += parseFloat(transaction.amount);

          if (transaction.amount > 0) {
            dataMonths[index].income += transaction.amount;
          } else {
            dataMonths[index].outlay += transaction.amount;
          }
        }
      });
  }

  return { totalCashflow, dataMonths };
};

function CashflowChart() {
  const { transactions } = useSelector((state: any) => state.bankReducer);

  const [totalCashflow, settotalCashflow] = useState(0);
  const [dataMonths, setDataMonths] = useState([]);

  useEffect(() => {
    const data = setData(transactions);

    settotalCashflow(data.totalCashflow);
    setDataMonths(data.dataMonths);
  }, [transactions]);

  return (
    <Card
      title={"Cashflow"}
      className="overflow-hidden h-100"
      action={
        <div className="d-flex flex-column" style={{ textAlign: "right" }}>
          <span
            style={{
              color: "var(--primary-bg-color)",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {getCurrencyValue(totalCashflow)}
          </span>
          <span
            style={{
              color: totalCashflow > 0 ? "var(--green)" : "var(--red)",
            }}
          >
            Cashflow cumulé
          </span>
        </div>
      }
    >
      <>
        <p
          className="mb-0"
          style={{ color: "rgba(44,56,74,.38)", marginTop: -50 }}
        >
          {moment().subtract(11, "months").format("MM/YYYY")} -{" "}
          {moment().format("MM/YYYY")}
        </p>
        <div
          style={{
            margin: "0 -20px -15px -20px",
            overflow: "hidden",
            width: "cal(100% + 40px)",
          }}
        >
          <ResponsiveContainer
            height={
              window.screen.width > 1000 && window.screen.height > 1000
                ? 300
                : 200
            }
          >
            <AreaChart
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              data={[...dataMonths].reverse()}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#6C39FF" stopOpacity={0.35} />
                  <stop offset="95%" stopColor="#6C39FF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Tooltip
                labelFormatter={(name, payload: any) => {
                  if (payload[0] && payload[0].payload) {
                    return payload[0].payload.name;
                  }
                }}
                formatter={(value) => {
                  return getCurrencyValue(parseFloat(value.toString()));
                }}
              />
              <Area
                type="monotone"
                name="Total"
                dataKey="total"
                fillOpacity={1}
                fill="url(#colorUv)"
                stroke="#6C39FF"
                strokeWidth={2}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </>
    </Card>
  );
}

export default CashflowChart;
