import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../reducers";

import { insertEditor } from "../../actions/editorAction";

import { generateString } from "../../utils/general";

function InsertEditor() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { type } = useParams();

  const [token, setToken] = useState(generateString());

  const { editors } = useSelector((state: any) => state.editorReducer);

  useEffect(() => {
    if (type) {
      dispatch(insertEditor(token, type));
    }
  }, []);

  useEffect(() => {
    if (token) {
      const idx = editors.findIndex((element: any) => element.token === token);

      if (idx >= 0) {
        navigate(token);
      }
    }
  }, [editors]);

  if (!type) {
    return <p>Erreur</p>;
  }

  return <p>Création du document en cours</p>;
}

export default InsertEditor;
