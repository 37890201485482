import { useEffect, useState } from "react";
import { Button, InputSelect } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../reducers";

import {
  getBanks,
  getConnectLink,
  updateBank,
} from "../../../actions/bankAction";

function ConnectBank() {
  const dispatch = useAppDispatch();

  const [bank, setBank] = useState("");

  const { banks, connectLink } = useSelector(
    (state: any) => state.bankReducer
  );

  useEffect(() => {
    if (connectLink) {
      window.open(connectLink, "_self");
    }
  }, [connectLink]);

  const { isBankId } = useSelector((state: any) => state.authReducer);

  return (
    <>
      {isBankId ? (
        <>
          <Button
            onClick={() => {
              dispatch(updateBank());
            }}
            text="Synchroniser le compte"
          />
        </>
      ) : (
        <>
          {banks && banks.length ? (
            <div className="d-flex" style={{ gap: 10 }}>
              <InputSelect
                className="mt-auto mb-auto"
                placeholder="Veuillez sélectionner une banque"
                value={bank}
                options={banks.map((bank: any) => ({
                  value: bank.id,
                  text: bank.name,
                }))}
                onChange={(value) => {
                  setBank(value.toString());
                }}
              />
              <Button
                disabled={!bank}
                onClick={() => {
                  dispatch(getConnectLink(bank));
                }}
                text="Démarrer"
              />
            </div>
          ) : (
            <Button
              onClick={() => {
                dispatch(getBanks());
              }}
              text="Connecter un compte bancaire"
            />
          )}
        </>
      )}
    </>
  );
}

export default ConnectBank;
