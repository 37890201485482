import { useState } from "react";
import { InputText, Card, Button } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import { Row, Col } from "react-flexbox-grid";

import { changePassword } from "../../actions/authAction";
import { useAppDispatch } from "../../reducers";
import { TbaseReducer } from "../../types/reducers/reducersType";
import { updateProfil } from "../../actions/profilAction";

export type TinformationsData = {
  name: string;
  phone: string;
};
function Profil() {
  const dispatch = useAppDispatch();

  const { isError, isSuccess, name, email, userId, phone } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isErrorInput, setIsErrorInput] = useState<boolean>(false);
  const [informtionsData, setInformationsdata] = useState({
    name,
    phone,
  });

  const _changePassword = () => {
    if (
      oldPassword &&
      password &&
      confirmPassword &&
      password === confirmPassword
    ) {
      dispatch(changePassword(oldPassword, password));
    } else {
      setIsErrorInput(true);
    }
  };

  const handleOnInformationsChange = (
    key: keyof TinformationsData,
    value: string
  ) => {
    setInformationsdata((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdateInformations = () => {
    dispatch(updateProfil({ ...informtionsData, userId }));
  };

  return (
    <>
      <h2 className="m-0">Mon compte</h2>
      <Card title="Informations">
        <Row style={{ rowGap: 16 }}>
          <Col xs={12} md={6} lg={4}>
            <InputText
              placeholder="Nom"
              value={informtionsData.name}
              onChange={(e) => {
                handleOnInformationsChange("name", e.target.value);
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <InputText placeholder="E-mail" value={email} disabled />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <InputText
              placeholder="Numéro de téléphone"
              value={informtionsData.phone}
              onChange={(e) => {
                handleOnInformationsChange("phone", e.target.value);
              }}
            />
          </Col>
          <Col xs={12} className="d-flex">
            <Button
              style={{
                marginLeft: "auto",
              }}
              text="Enregistrer"
              onClick={handleUpdateInformations}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Modification du mot de passe">
        <Row style={{ rowGap: 16 }}>
          <Col xs={12} md={4}>
            <InputText
              label="Ancien mot de passe"
              type="password"
              placeholder="*******"
              value={oldPassword}
              onChange={({ target }: { target: { value: string } }) =>
                setOldPassword(target.value)
              }
            />
          </Col>
          <Col xs={12} md={4}>
            <InputText
              label="Nouveau mot de passe"
              type="password"
              placeholder="*******"
              value={password}
              onChange={({ target }: { target: { value: string } }) =>
                setPassword(target.value)
              }
            />
          </Col>
          <Col xs={12} md={4}>
            <InputText
              label="Confirmer le mot de passe"
              type="password"
              placeholder="*******"
              value={confirmPassword}
              onChange={({ target }: { target: { value: string } }) =>
                setConfirmPassword(target.value)
              }
            />
          </Col>
          <Col xs={12} className="d-flex">
            {isSuccess ? (
              <p style={{ margin: "auto", marginLeft: 0, color: "#0c8540" }}>
                Le mot de passe a été modifié
              </p>
            ) : null}
            {isError ? (
              <p style={{ margin: "auto", marginLeft: 0, color: "#B71C1C" }}>
                Une erreur est survenue
              </p>
            ) : null}
            {isErrorInput ? (
              <p style={{ margin: "auto", marginLeft: 0, color: "#B71C1C" }}>
                Veuillez remplir les champs obligatoires
              </p>
            ) : null}
            <Button
              style={{ marginLeft: "auto" }}
              text="Modifier"
              onClick={() => _changePassword()}
            />
          </Col>
        </Row>
      </Card>
      <Card title="Version">
        <p>Version : <b>0.0.1 (bêta)</b></p>
      </Card>
    </>
  );
}

export default Profil;
