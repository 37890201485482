import { TOrganizationInitialState } from "../types/organization/organizationType";

export const initialState = {
  organisationDocuments: [],
} satisfies TOrganizationInitialState;

export const organizationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET-ORGANIZATION-DOCUMENTS":
      return {
        ...state,
        organisationDocuments: action.success ? action.data : [],
      };
    default:
      return state;
  }
};
