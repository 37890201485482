import { useEffect, useState, useMemo } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Cell,
  XAxis,
  YAxis,
  Text,
} from "recharts";
import { useSelector } from "react-redux";

import { getCurrencyValue } from "../../../utils/general";

let ctx: any;

export const measureText = (text: string) => {
  if (!ctx) {
    ctx = document.createElement("canvas").getContext("2d");
    ctx.font = "16px 'Poppins";
  }

  return ctx.measureText(text).width;
};

const setData = (housings: any) => {
  let totalIncome = 0;
  let dataGroups: any = {};

  for (let index = 0; index < housings.length; index++) {
    const element = housings[index];

    if (!dataGroups.hasOwnProperty(element.group)) {
      dataGroups[element.group] = {
        name: element.city,
        total: 0,
        rent: 0,
        charge: 0,
      };
    }

    dataGroups[element.group].total +=
      parseFloat(element.rent) + parseFloat(element.charge);
    dataGroups[element.group].rent += parseFloat(element.rent);
    dataGroups[element.group].charge += parseFloat(element.charge);

    totalIncome += parseFloat(element.rent) + parseFloat(element.charge);
  }

  return { totalIncome, dataGroups };
};

//@ts-ignore
const YAxisLeftTick = ({ y, payload }) => {
  return (
    <Text x={0} y={y} textAnchor="start" verticalAnchor="middle">
      {payload.value.substring(0, 5)}
    </Text>
  );
};

function IncomeGroupChart() {
  const { housings } = useSelector((state: any) => state.housingReducer);

  const [totalIncome, setTotalIncome] = useState(0);
  const [dataGroups, setDataGroups] = useState([]);

  useEffect(() => {
    const data = setData(housings);

    setTotalIncome(data.totalIncome);
    setDataGroups(data.dataGroups);
  }, [housings]);

  const maxTextWidth = useMemo(() => {
    const data = Object.keys(dataGroups).map((key: any) => dataGroups[key]);

    return data.reduce((acc, cur) => {
      const value = getCurrencyValue(cur["total"]);
      //@ts-ignore
      const width = measureText(value.toLocaleString());
      if (width > acc) {
        return width;
      }
      return acc;
    }, 0);
  }, [dataGroups, "total"]);

  return (
    <Card
      title={"Recettes / Groupe"}
      className="overflow-hidden h-100"
      action={
        <div className="d-flex flex-column" style={{ textAlign: "right" }}>
          <span
            style={{
              color: "var(--primary-bg-color)",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {getCurrencyValue(totalIncome / Object.keys(dataGroups).length)}
          </span>
          <span
            style={{
              color: "#C7C7C7",
            }}
          >
            Recettes Moyenne / Groupe
          </span>
        </div>
      }
    >
      <>
        <div
          style={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ResponsiveContainer
            width={"100%"}
            height={60 * Object.keys(dataGroups).length}
            debounce={50}
          >
            <BarChart
              data={Object.keys(dataGroups).map((key: any) => dataGroups[key])}
              layout="vertical"
              margin={{ left: 0, right: maxTextWidth }}
            >
              <Tooltip
                labelFormatter={(name, payload: any) => {
                  if (payload[0] && payload[0].payload) {
                    return payload[0].payload.name;
                  }
                }}
                formatter={(value) => {
                  return getCurrencyValue(parseFloat(value.toString()));
                }}
              />
              <XAxis hide axisLine={false} type="number" />
              <YAxis
                yAxisId={0}
                dataKey={"name"}
                type="category"
                axisLine={false}
                tickLine={false}
                tick={YAxisLeftTick}
              />
              <YAxis
                orientation="right"
                yAxisId={1}
                dataKey={"total"}
                type="category"
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => getCurrencyValue(value)}
                mirror
                tick={{
                  transform: `translate(${maxTextWidth + 10}, 0)`,
                }}
              />
              <Bar
                dataKey="total"
                fill="#6c39ff"
                radius={12}
                minPointSize={2}
                barSize={32}
                name="Recettes"
              >
                {Object.keys(dataGroups).map((key: any) => {
                  return (
                    <Cell
                      key={
                        //@ts-ignore
                        dataGroups[key].name
                      }
                      fill={"#6c39ff"}
                    />
                  );
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    </Card>
  );
}

export default IncomeGroupChart;
