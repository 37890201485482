import { useState } from "react";
import { Card, Flex } from "@sahadevia/ui-kit-modern-sahadevia";
import { Col, Row } from "react-flexbox-grid";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
} from "recharts";

import Gauge from "../../components/Gauge";

import { getCurrencyValue, getNumber } from "../../utils/general";

import "../../styles/gauge-v2.css";

function Simulators() {
  const [maxLoan, setMaxLoan] = useState(1000000);
  const [inputs, setInputs] = useState<any>({
    total: "200000",
    duration: "15",
    interest: "4.00",
    insurance: "0.30",
  });

  const handleChange = (value: string, name: string) =>
    setInputs((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

  const calculateLoanCost = (
    loanAmount: number,
    durationYears: number,
    interestRate: number,
    insuranceRate: number
  ) => {
    // Convert interest and insurance rates to decimals
    interestRate /= 100;
    insuranceRate /= 100;

    // Convert loan duration from years to months
    var durationMonths = durationYears * 12;

    // Calculate monthly insurance cost
    var monthlyInsurance = (loanAmount * insuranceRate) / 12;

    // Calculate monthly interest rate
    var monthlyInterestRate = interestRate / 12;

    // Calculate monthly payment without insurance
    var monthlyPaymentWithoutInsurance =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -durationMonths));

    // Calculate total monthly payment (with insurance)
    var totalMonthlyPayment = monthlyPaymentWithoutInsurance + monthlyInsurance;

    // Calculate total cost of credit (sum of monthly payments)
    var totalCreditCost = totalMonthlyPayment * durationMonths - loanAmount;

    // Calculate total cost insurance
    var totalCostOfInsurance = monthlyInsurance * durationMonths;

    // Return the results
    return {
      monthlyPaymentWithoutInsurance: monthlyPaymentWithoutInsurance,
      monthlyInsurance: monthlyInsurance,
      totalMonthlyPayment: totalMonthlyPayment,
      totalCreditCost: totalCreditCost,
      totalCostOfInsurance: totalCostOfInsurance,
    };
  };

  const resultLoanCost = calculateLoanCost(
    parseFloat(inputs.total || 0),
    parseFloat(inputs.duration || 0),
    parseFloat(inputs.interest || 0),
    parseFloat(inputs.insurance || 0)
  );

  return (
    <>
      <h2 className="m-0">Simulateurs</h2>
      <Row style={{ rowGap: 16 }}>
        <Col xs={12} md={6}>
          <Card title="">
            <>
              <Gauge
                title="Montant emprunté"
                min={0}
                max={maxLoan}
                step={1000}
                value={inputs.total}
                onChange={(value) => handleChange(value, "total")}
              />
              <Gauge
                title="Durée"
                min={0}
                max={30}
                step={1}
                value={inputs.duration}
                onChange={(value) => handleChange(value, "duration")}
              />
              <Gauge
                title="Taux d'intérêt avant négociation"
                min={0}
                max={10}
                step={0.01}
                value={inputs.interest}
                onChange={(value) => handleChange(value, "interest")}
              />
              <Gauge
                title="Taux d'assurance"
                min={0}
                max={10}
                step={0.01}
                value={inputs.insurance}
                onChange={(value) => handleChange(value, "insurance")}
              />
            </>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card title="" className="h-100" classNameChildren="h-100">
            <Flex display="flex" direction="column" classNames="h-100" gap={10}>
              <Flex
                display="flex"
                direction="column"
                align="center"
                justify="center"
                classNames="h-100"
              >
                <span style={{ color: "var(--light-grey)" }}>
                  Votre mensualité sera de :
                </span>
                <span
                  style={{
                    color: "var(--primary-bg-color)",
                    fontSize: 50,
                  }}
                >
                  {getCurrencyValue(resultLoanCost.totalMonthlyPayment, 0)}
                </span>
              </Flex>
              <Flex display="flex" justify="space-between" align="center">
                <span>Montant du prêt</span>
                <span>{getCurrencyValue(inputs.total, 0)}</span>
              </Flex>
              <div
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "var(--light-grey)",
                }}
              />
              <Flex display="flex" justify="space-between" align="center">
                <span>Mensualité</span>
                <span>
                  {getCurrencyValue(resultLoanCost.totalMonthlyPayment, 0)} /
                  mois
                </span>
              </Flex>
              <Flex
                display="flex"
                justify="space-between"
                align="center"
                styles={{
                  color: "var(--light-grey)",
                }}
              >
                <span>dont assurance</span>
                <span>
                  {getCurrencyValue(resultLoanCost.monthlyInsurance, 0)} / mois
                </span>
              </Flex>
              <div
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "var(--light-grey)",
                }}
              />
              <Flex display="flex" justify="space-between" align="center">
                <span>Coût total du crédit</span>
                <span>
                  {getCurrencyValue(resultLoanCost.totalCreditCost, 0)}
                </span>
              </Flex>
              <Flex
                display="flex"
                justify="space-between"
                align="center"
                styles={{
                  color: "var(--light-grey)",
                }}
              >
                <span>Dont assurance</span>
                <span>
                  {getCurrencyValue(resultLoanCost.totalCostOfInsurance, 0)}
                </span>
              </Flex>
            </Flex>
          </Card>
        </Col>
        <Col xs={12}>
          <Card title="">
            <ResponsiveContainer
              height={
                window.screen.width > 1000 && window.screen.height > 1000
                  ? 400
                  : 200
              }
            >
              <ComposedChart
                margin={{
                  top: 20,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                data={[
                  {
                    name: "5 ans",
                    ...calculateLoanCost(
                      inputs.total,
                      5,
                      inputs.interest,
                      inputs.insurance
                    ),
                  },
                  {
                    name: "10 ans",
                    ...calculateLoanCost(
                      inputs.total,
                      10,
                      inputs.interest,
                      inputs.insurance
                    ),
                  },
                  {
                    name: "15 ans",
                    ...calculateLoanCost(
                      inputs.total,
                      15,
                      inputs.interest,
                      inputs.insurance
                    ),
                  },
                  {
                    name: "20 ans",
                    ...calculateLoanCost(
                      inputs.total,
                      20,
                      inputs.interest,
                      inputs.insurance
                    ),
                  },
                  {
                    name: "25 ans",
                    ...calculateLoanCost(
                      inputs.total,
                      25,
                      inputs.interest,
                      inputs.insurance
                    ),
                  },
                ]}
              >
                <YAxis
                  dataKey="totalMonthlyPayment"
                  yAxisId={2}
                  tickFormatter={(value) => {
                    return getNumber(value);
                  }}
                />
                <YAxis
                  dataKey="totalCreditCost"
                  yAxisId={1}
                  orientation="right"
                  tickFormatter={(value) => {
                    return `${getNumber(value / 1000)}k`;
                  }}
                />
                <XAxis dataKey="name" />
                <Tooltip
                  formatter={(value) => {
                    return getCurrencyValue(parseInt(value.toString()), 0);
                  }}
                />
                <Legend layout="vertical" verticalAlign="top" align="center" />
                <Bar
                  type="monotone"
                  dataKey="totalMonthlyPayment"
                  yAxisId={2}
                  fill="#6C39FF"
                  radius={12}
                  barSize={50}
                  name="Mensualité"
                />
                <Line
                  type="monotone"
                  dataKey="totalCreditCost"
                  yAxisId={1}
                  stroke="#C7C7C7"
                  strokeWidth={4}
                  name="Coût total du crédit"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Simulators;
