/* Libs */
import { Flex, Icon, Table } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Reducers */
import { useAppDispatch } from "../../../reducers";

/* Types */
import { TbaseReducer } from "../../../types/reducers/reducersType";

/* Actions */
import { downloadDocument } from "../../../actions/organizationAction";

/* Utils */
import { getCurrencyValue } from "../../../utils/general";

function DocumentsTable() {
  const dispatch = useAppDispatch();

  const { organisationDocuments } = useSelector(
    (state: TbaseReducer) => state.organizationReducer
  );

  const handleDownload = (id: string) => {
    dispatch(
      downloadDocument(id, (result) => {
        const { success, data } = result;
        if (success) {
          const { base64 } = data;
          const link = document.createElement("a");
          link.href = "data:application/pdf;base64," + base64;
          link.target = "_blank";
          link.download = "Document";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
    );
  };

  return (
    <Table
      title="Documents (Devis / Factures)"
      data={organisationDocuments.map((element: any) => ({
        ...element,
        price: getCurrencyValue(element.price),
      }))}
      columns={[
        {
          key: "id",
          label: "#",
        },
        {
          key: "insertDate",
          label: "Date",
        },

        {
          key: "price",
          label: "Montant",
        },
        {
          key: "actions",
          label: "Actions",
          component: (line) => (
            <Flex gap={10}>
              <Icon
                infoTop
                infoText="Télécharger"
                backgroundHover
                icon="Download"
                size={16}
                onClick={() => {
                  const { id } = line;
                  handleDownload(id);
                }}
              />
            </Flex>
          ),
        },
      ]}
      context={undefined}
    />
  );
}

export default DocumentsTable;
