import { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { ResponsiveContainer, Bar, Tooltip, BarChart, Legend } from "recharts";
import moment from "moment";
import { useSelector } from "react-redux";

import { getCurrencyValue } from "../../../utils/general";

const setData = (transactions: any) => {
  let totalCashflow = 0;
  let countMonthNotEmpty: any = [];
  let dataMonths: any = Array(12).fill({});

  for (let index = 11; index >= 0; index--) {
    dataMonths[index] = {
      name: moment().subtract(index, "months").format("MM/YYYY"),
      total: 0,
      income: 0,
      outlay: 0,
    };

    transactions
      .filter((transaction: any) => !transaction.hide)
      .forEach((transaction: any) => {
        if (
          moment().subtract(index, "months").format("YYYY-MM") ===
          moment(transaction.date).format("YYYY-MM")
        ) {
          if (
            !countMonthNotEmpty.includes(
              moment(transaction.date).format("YYYY-MM")
            )
          ) {
            countMonthNotEmpty.push(moment(transaction.date).format("YYYY-MM"));
          }

          dataMonths[index].total += parseFloat(transaction.amount);
          totalCashflow += parseFloat(transaction.amount);

          if (transaction.amount > 0) {
            dataMonths[index].income += transaction.amount;
          } else {
            dataMonths[index].outlay += transaction.amount * -1;
          }
        }
      });
  }

  return { totalCashflow, dataMonths, countMonthNotEmpty };
};

function CashflowChart() {
  const { transactions } = useSelector((state: any) => state.bankReducer);

  const [totalCashflow, settotalCashflow] = useState(0);
  const [dataMonths, setDataMonths] = useState([]);
  const [countMonthNotEmpty, setCountMonthNotEmpty] = useState([]);

  useEffect(() => {
    const data = setData(transactions);

    settotalCashflow(data.totalCashflow);
    setDataMonths(data.dataMonths);
    setCountMonthNotEmpty(data.countMonthNotEmpty);
  }, [transactions]);

  return (
    <Card
      title={"Recettes / Dépenses"}
      className="overflow-hidden"
      action={
        <div className="d-flex flex-column" style={{ textAlign: "right" }}>
          <span
            style={{
              color: "var(--primary-bg-color)",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {getCurrencyValue(totalCashflow / countMonthNotEmpty.length)}
          </span>
          <span
            style={{
              color:
                totalCashflow / countMonthNotEmpty.length > 0
                  ? "var(--green)"
                  : "var(--red)",
            }}
          >
            Cashflow moyen
          </span>
        </div>
      }
    >
      <>
        <p
          className="mb-0"
          style={{ color: "rgba(44,56,74,.38)", marginTop: -50 }}
        >
          {moment().subtract(11, "months").format("MM/YYYY")} -{" "}
          {moment().format("MM/YYYY")}
        </p>
        <div
          style={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ResponsiveContainer
            height={
              window.screen.width > 1000 && window.screen.height > 1000
                ? 300
                : 200
            }
          >
            <BarChart
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              data={[...dataMonths].reverse()}
            >
              <Legend layout="vertical" verticalAlign="top" align="center" />
              <Tooltip
                labelFormatter={(name, payload: any) => {
                  if (payload[0] && payload[0].payload) {
                    return payload[0].payload.name;
                  }
                }}
                formatter={(value) => {
                  return getCurrencyValue(parseFloat(value.toString()));
                }}
              />
              <Bar
                type="monotone"
                dataKey="outlay"
                yAxisId={2}
                fill="#6c39ff"
                radius={12}
                barSize={50}
                name="Dépenses"
              />
              <Bar
                type="monotone"
                dataKey="income"
                yAxisId={2}
                fill="#d942ff"
                radius={12}
                barSize={50}
                name="Recettes"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    </Card>
  );
}

export default CashflowChart;
