import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { Row, Col } from "react-flexbox-grid";

import CashflowChart from "../../components/dashboard/home/CashflowChart";
import Calendar from "../../components/dashboard/home/Calendar";

import Cashflow from "../../components/dashboard/bank/Cashflow";
import Balance from "../../components/dashboard/bank/Balance";
import Income from "../../components/dashboard/bank/Income";
import Outlay from "../../components/dashboard/bank/Outlay";

function Home() {
  return (
    <>
      <h2 className="m-0">Tableau de bord</h2>
      <Row style={{ rowGap: 16 }}>
        <Col xs={12} md={12} lg={6}>
          <Row style={{ rowGap: 16 }}>
            <Col xs={12} md={12} lg={6}>
              <Balance />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <Cashflow />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <Income isYear />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <Outlay isYear />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={12} lg={6}>
          <Calendar />
        </Col>
        <Col xs={12} md={12} lg={8}>
          <CashflowChart />
        </Col>
        <Col xs={12} md={12} lg={4}>
          <Row style={{ rowGap: 16 }} className="h-100">
            <Col xs={12} md={12} lg={12}>
              <Card title="Attente de paiements" className="h-100">
                <></>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Home;
