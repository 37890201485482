import { IconProps } from "@sahadevia/ui-kit-modern-sahadevia";

const SmallLogo = ({
  size,
  color = "var(--primary-icon-color)",
  ...props
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 35 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_455_282)">
      <path
        d="M35 25.203C35 29.4448 33.3864 33.3981 30.4737 36.4687C29.794 37.1927 29.0396 37.8587 28.2106 38.4666L28.1803 38.4957C26.6272 39.6534 24.9369 40.5227 23.1276 41.1016C22.117 41.4346 21.0601 41.6804 20.0193 41.8102C19.1903 41.9399 18.3593 41.9979 17.5 41.9979C16.6407 41.9979 15.8097 41.9399 14.9666 41.8102C13.9096 41.6804 12.8547 41.4346 11.8442 41.0861C10.2749 40.5653 8.76614 39.8412 7.42479 38.9139C7.213 38.7842 7.01735 38.639 6.82169 38.4938C5.99268 37.8704 5.22216 37.1908 4.5283 36.4667C1.61365 33.4001 0 29.4467 0 25.203C0 20.9592 1.55515 17.153 4.36693 14.1212C4.42139 14.0612 4.52628 14.0999 4.52628 14.1793V16.8529C4.52628 16.9439 4.49804 17.033 4.4456 17.1085C2.838 19.4859 1.96058 22.266 1.96058 25.201C1.96058 28.136 2.86624 31.0227 4.52426 33.4253C5.32302 34.5694 6.28919 35.6265 7.42076 36.5384V0.14702C7.42076 0.0211785 7.57607 -0.0465822 7.67491 0.0366666L12.0197 3.73834C12.1185 3.82158 12.1729 3.94162 12.1729 4.06746V39.1907C12.1729 39.1907 12.175 39.1927 12.177 39.1946C13.1189 39.5392 14.0972 39.7986 15.1158 39.9283V11.8173C15.1158 11.6915 15.2711 11.6237 15.37 11.707L19.7288 15.4106C19.8277 15.4938 19.8842 15.6139 19.8842 15.7397V39.9303C20.8786 39.7851 21.8367 39.5663 22.819 39.2159L22.825 39.2062V21.5768C22.825 21.451 22.9803 21.3832 23.0792 21.4665L27.4239 25.1681C27.5228 25.2514 27.5772 25.3714 27.5772 25.4973V36.5539C28.7088 35.642 29.675 34.5849 30.4737 33.4272C32.1338 31.0227 33.0374 28.1864 33.0374 25.203C33.0374 22.2196 32.1338 19.4472 30.5524 17.124C30.4999 17.0465 30.4717 16.9575 30.4717 16.8665V14.1812C30.4717 14.1018 30.5766 14.0631 30.6311 14.1231C33.342 17.0853 34.998 20.9554 34.998 25.203H35Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_455_282">
        <rect width="35" height="42" fill={color} />
      </clipPath>
    </defs>
  </svg>
);
export default SmallLogo;
