import { Row, Col } from "react-flexbox-grid";

import CashflowChart from "../../components/dashboard/home/CashflowChart";
import IncomeGroupChart from "../../components/dashboard/statistics/IncomeGroupChart";
import IncomeOutlayChart from "../../components/dashboard/statistics/IncomeOutlayChart";

import Income from "../../components/dashboard/bank/Income";
import Outlay from "../../components/dashboard/bank/Outlay";

function Statistics() {
  return (
    <>
      <h2 className="m-0">Statistiques</h2>
      <Row style={{ rowGap: 16 }}>
        <Col xs={12} md={6} lg={3}>
          <Income isYear />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Outlay isYear />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Income />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Outlay />
        </Col>
        <Col xs={12} md={12} lg={8}>
          <CashflowChart />
        </Col>
        <Col xs={12} md={12} lg={4}>
          <IncomeGroupChart />
        </Col>
        <Col xs={12} md={12} lg={12}>
          <IncomeOutlayChart />
        </Col>
      </Row>
    </>
  );
}

export default Statistics;
