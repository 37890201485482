import Card from "./general/Card";

type LineProps = {
  title: string;
  date: string;
};

function Line({ title, date }: LineProps) {
  return (
    <div
      className="d-flex flex-column p-10 cursor-pointer"
      style={{
        borderRadius: 8,
        backgroundColor: "#FFFEFB",
        boxShadow: "2px 4px 11px 0px rgba(41, 41, 41, 0.05)",
      }}
    >
      <span>{title}</span>
      <span
        style={{
          color: "#ADADAD",
          fontSize: 15,
        }}
      >
        {date}
      </span>
    </div>
  );
}

type FilesProps = {
  data: Array<any>;
};

function Files({ data }: FilesProps) {
  return (
    <Card title="Fichiers">
      <div className="p-20 pt-0 pb-O">
        <h3
          style={{
            color: "#3C3C3C",
            fontSize: 18,
          }}
        >
          Fichiers récents
        </h3>
        <div
          className="d-flex flex-column"
          style={{
            gap: 20,
          }}
        >
          {data.length ? (
            <>
              {data.map((file, idx) => (
                <Line key={idx} title={file.title} date={file.date} />
              ))}
            </>
          ) : (
            <span
              className="m-auto"
              style={{
                color: "#ADADAD",
                textAlign: "center",
              }}
            >
              Aucun fichier
            </span>
          )}
        </div>
      </div>
    </Card>
  );
}

export default Files;
