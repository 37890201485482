import { useState } from "react";
import { Button, Flex, Icon, Table } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import { getCurrencyValue, getFromValue } from "../../utils/general";

import ModalHousing from "../../components/dashboard/modal/ModalHousing";
import ModalHistories from "../../components/dashboard/modal/ModalHistories";

interface HousingTableProps {
  group: string;
}

function Housings() {
  const [isModalHousing, setIsModalHousing] = useState(false);
  const [housingIdModalHistories, setHousingIdModalHistories] = useState(0);

  const { housings } = useSelector((state: any) => state.housingReducer);
  const { tenants } = useSelector((state: any) => state.tenantReducer);

  let groups: Array<string> = [];

  housings.forEach((housing: any) => {
    if (!groups.includes(housing.group)) {
      groups.push(housing.group);
    }
  });

  const HousingTable = ({ group }: HousingTableProps) => {
    const data = housings.filter((housing: any) => housing.group === group);

    const totalRent = data.reduce(
      (accumulator: number, currentValue: any) =>
        accumulator + parseFloat(currentValue.rent),
      0
    );
    const totalCharge = data.reduce(
      (accumulator: number, currentValue: any) =>
        accumulator + parseFloat(currentValue.charge),
      0
    );

    return (
      <Table
        title={`${group} - ${getCurrencyValue(totalRent)} |  ${getCurrencyValue(
          totalCharge
        )}`}
        context={null}
        columns={[
          {
            key: "idx",
            label: "#",
          },
          {
            key: "reference",
            label: "Référence",
          },
          {
            key: "city",
            label: "Ville",
          },
          {
            key: "zipCode",
            label: "Code postal",
          },
          {
            key: "address",
            label: "Adresse",
          },
          {
            key: "tenant",
            label: "Locataire",
          },
          {
            key: "number",
            label: "N°",
          },
          {
            key: "rent",
            label: "Loyer",
          },
          {
            key: "charge",
            label: "Charge",
          },
          {
            key: "room",
            label: "Pièces",
          },
          {
            key: "size",
            label: "Taille",
          },
          {
            key: "actions",
            label: "Actions",
            component: (line) => (
              <Flex gap={10}>
                <Icon
                  infoText="Historique"
                  backgroundHover
                  icon="Search"
                  size={16}
                  onClick={() => setHousingIdModalHistories(line.housingId)}
                />
              </Flex>
            ),
          },
        ]}
        data={data.map((housing: any, idx: number) => {
          const tenant = getFromValue(tenants, "id", housing.tenantId);

          return {
            idx: idx + 1,
            housingId: housing.id,
            reference: housing.reference,
            city: housing.city,
            zipCode: housing.zipCode,
            address: housing.address,
            tenant: tenant.id
              ? `${tenant.kind} ${tenant.surname} ${tenant.name}`
              : "Libre",
            tenantId: housing.tenantId,
            number: housing.number,
            rent: getCurrencyValue(housing.rent),
            rentValue: housing.rent,
            charge: getCurrencyValue(housing.charge),
            chargeValue: housing.charge,
            room: housing.room,
            size: housing.size,
          };
        })}
      />
    );
  };

  return (
    <>
      <Flex classNames="justify-between">
        <h2 className="m-0">Logements</h2>
        <Button
          text="Ajouter un logement"
          onClick={() => setIsModalHousing(true)}
        />
      </Flex>
      {groups.map((group, idx) => (
        <HousingTable key={idx} group={group} />
      ))}

      {isModalHousing ? (
        <ModalHousing onClose={() => setIsModalHousing(false)} />
      ) : (
        <></>
      )}
      {housingIdModalHistories ? (
        <ModalHistories
          onClose={() => setHousingIdModalHistories(0)}
          housingId={housingIdModalHistories}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Housings;
