import moment from "moment";

const bail: TTemplateEditorsBase = {
  body: [
    {
      title: "Votre location est...",
      elements: [
        {
          name: "rentType",
          type: "Radio",
          texts: ["Une location vide", "Une location meublée"],
        },
      ],
    },
    {
      title: "Qui est le Bailleur ?",
      elements: [
        {
          name: "ownerType",
          type: "Radio",
          texts: [
            "Le bailleur est un particulier",
            "Le bailleur est une societé",
          ],
        },
        {
          name: "ownerName",
          type: "InputText",
          title: "Nom",
        },
        {
          name: "ownerAddress",
          type: "InputText",
          title: "Adresse",
        },
        {
          name: "ownerZipCode",
          type: "InputText",
          title: "Code postal",
        },
        {
          name: "ownerCity",
          type: "InputText",
          title: "Ville",
        },
        {
          name: "companyIdentity",
          type: "InputText",
          title: "Identité de la société",
          condition: {
            key: "ownerType",
            value: ["Le bailleur est une societé"],
          },
        },
        {
          name: "companySiren",
          type: "InputText",
          title: "N° SIREN",
          condition: {
            key: "ownerType",
            value: ["Le bailleur est une societé"],
          },
        },
        {
          name: "legalForm",
          type: "InputSelect",
          title: "Forme juridique",
          texts: [
            "SCI (familiale)",
            "SCI (non familiale)",
            "SARL",
            "EURL",
            "SA",
            "SAS",
            "SASU",
            "SELARL",
            "SNC",
            "SCP",
            "Autre / étrangère",
          ],
          condition: {
            key: "ownerType",
            value: ["Le bailleur est une societé"],
          },
        },
      ],
    },
    {
      title: "Qui est le Locataire ?",
      elements: [
        {
          name: "tenantSurname",
          type: "InputText",
          title: "Nom",
        },
        {
          name: "tenantName",
          type: "InputText",
          title: "Prénom",
        },
      ],
    },
    {
      title: "Où se situe le bien loué ?",
      elements: [
        {
          name: "housingAddress1",
          type: "InputText",
          title: "Adresse",
        },
        {
          name: "housingAddress2",
          type: "InputText",
          title: "Complément d'adresse",
        },
        {
          name: "housingZipCode",
          type: "InputText",
          title: "Code postal",
        },
        {
          name: "housingCity",
          type: "InputText",
          title: "Ville",
        },
      ],
    },
    {
      title: "Description du bien loué et diagnostics",
      elements: [
        {
          name: "housingType",
          type: "InputSelect",
          title: "Dans quel type d'immeuble est le bien loué ?",
          texts: ["Immeuble collectif", "Immeuble individuel"],
        },
        {
          name: "housingType2",
          type: "InputSelect",
          title: "S'agit-il d'une mono ou d'une copropriété ?",
          texts: ["Monopropriété", "Copropriété"],
        },
        {
          name: "housingYear",
          type: "InputSelect",
          title: "Quelle est la période de construction de l'immeuble ?",
          texts: [
            "Avant 1949",
            "De 1949 à 1974",
            "De 1975 à 1989",
            "De 1990 à 2005",
            "Depuis 2005",
          ],
        },
        {
          name: "housingElectric15",
          type: "Checkbox",
          title: "Votre installation électrique a-t-elle plus de 15 ans ?",
          texts: ["Oui", "Non"],
        },
        {
          name: "housingGaz",
          type: "Checkbox",
          title: "Avez-vous une installation au gaz ?",
          texts: [
            "Non",
            "Oui, elle a moins de 15 ans",
            "Oui, elle a plus de 15 ans",
          ],
        },
      ],
    },
    {
      title: "Superficie et usage",
      elements: [
        {
          name: "housingSize",
          type: "InputText",
          title: "Quelle est la surface habitable du bien loué ?",
        },
        {
          name: "housingRoom",
          type: "InputText",
          title: "Combien y a-t-il de pièces principales ?",
        },
        {
          name: "housingUsage",
          type: "InputSelect",
          title: "Quel sera l'usage des locaux ?",
          texts: [
            "Usage mixte professionnel et d'habitation",
            "Usage d'habitation",
            "Usage mixte professionnel et d'habitation",
          ],
        },
      ],
    },
    {
      title: "Chauffage et eau chaude",
      elements: [
        {
          name: "heatingIndividual",
          type: "InputSelect",
          title: "Le chauffage est-il individuel ou collectif ?",
          texts: ["Chauffage individuel", "Chauffage collectif"],
        },
        {
          name: "heatingIndividualRepare",
          type: "InputText",
          title: "Modalités de répartition du chauffage collectif",
          condition: {
            key: "heatingIndividual",
            value: ["Chauffage collectif"],
          },
        },
        {
          name: "hotWaterIndividual",
          type: "InputSelect",
          title: "L'eau chaude est-elle individuelle ou collective ?",
          texts: ["Eau cahude individuelle", "Eau chaude collective"],
        },
        {
          name: "yearCostEnergies",
          type: "InputText",
          title: "Modalités de répartition de l'eau chaude collective ",
          condition: {
            key: "hotWaterIndividual",
            value: ["Eau chaude collective"],
          },
        },
        {
          name: "DPE",
          type: "InputSelect",
          title:
            "Le diagnostic de performance énergétique (DPE) a classé le logement en... ",
          texts: [
            "Catégorie A",
            "Catégorie B",
            "Catégorie C",
            "Catégorie D",
            "Catégorie E",
            "Catégorie F",
            "Catégorie G",
          ],
        },
        {
          name: "costEnergies",
          type: "InputText",
          title: "J’estime les dépenses annuelles en énergie à...",
        },
        {
          name: "yearCostEnergies",
          type: "InputSelect",
          title: "Cette estimation est basée sur les prix de l'énergie en...",
          texts: Array.from({ length: 13 }, (_, index) =>
            (moment().year() - index).toString()
          ),
        },
      ],
    },
    {
      title: "Prestations et équipements du logement",
      elements: [
        {
          name: "optionnal",
          type: "Checkbox",
          title: "Surfaces non habitables",
          texts: [
            "Cave",
            "Parking",
            "Garage",
            "Balcon",
            "Jardin",
            "Terrasse",
            "Grenier",
            "Autres",
          ],
        },
        {
          name: "equipments",
          type: "Checkbox",
          title: "Équipements du logement",
          texts: ["Installations sanitaires", "Cuisine équipée", "Autres"],
        },
        {
          name: "otherEquipments",
          type: "Checkbox",
          title:
            "Équipements d'accès aux technologies de l'information et de la communication",
          texts: [
            "Raccordement TV",
            "Raccordement Internet",
            "Raccordemement fibre optique",
          ],
        },
      ],
    },
    {
      title: "Date et durée du bail",
      elements: [
        {
          name: "dateStartRent",
          type: "InputText",
          title: "Quand débute la location",
        },
        {
          name: "duringRent",
          type: "InputText",
          title: "Quelle est la durée du bail ?",
        },
      ],
    },
    {
      title: "Loyer hors charges",
      elements: [
        {
          name: "rent",
          type: "InputText",
          title: "Quel est le loyer mensuel hors charges",
        },
        {
          name: "works",
          type: "Checkbox",
          title: "Avez-vous fait des travaux depuis la précédente location ?",
          texts: ["Oui", "Non"],
        },
      ],
    },
    {
      title: "Révision du loyer",
      elements: [
        {
          name: "rentReview",
          type: "InputSelect",
          title: "Quand souhaitez-vous réviser le loyer ?",
          texts: [
            "1er du mois suivant l'anniversaire",
            "date d'anniversaire du bail",
            "Autre date",
            " Je ne souhaite pas réviser le loyer",
          ],
        },
        {
          name: "rentReviewDate",
          type: "DayMonthSelect",
          title: "Quand souhaitez-vous réviser le loyer ?",
          condition: {
            key: "rentReview",
            value: ["Autre date"],
          },
        },
      ],
    },
    {
      title: "Charges locatives",
      elements: [
        {
          name: "typeCharge",
          type: "InputSelect",
          title: "Comment souhaitez-vous récupérer les charges ?",
          texts: ["Au réel, avec régularisation annuelle", "Pas de charges"],
        },
        {
          name: "charge",
          type: "InputText",
          title: "Quel est le montant mensuel des charges ?",
          condition: {
            key: "typeCharge",
            value: ["Au réel, avec régularisation annuelle"],
          },
        },
      ],
    },
    {
      title: "Modalités de paiement",
      elements: [
        {
          name: "datePay",
          type: "Checkbox",
          title: "Le loyer est payé pour...",
          texts: ["Le mois à venir", "Le mois écoulé"],
        },
        {
          name: "maxDatePay",
          type: "InputText",
          title: "...au plus tard le...",
        },
        {
          name: "warranty",
          type: "InputText",
          title: "Quel est le montant du dépôt de garantie ?",
        },
        {
          name: "typePay",
          type: "InputSelect",
          title: "Quel est le mode de paiement du loyer ?",
          texts: [
            "Ne souhaite pas le préciser",
            "Virement",
            "Chèque",
            "Espèces",
          ],
        },
      ],
    },
    {
      title: "Y a-t-il des honoraires de location ? (Facultatif)",
      elements: [
        {
          name: "rentalFeesByPro",
          type: "Checkbox",
          texts: ["La location a été réalisée par un professionnel mandaté"],
        },
        {
          name: "inventoryFeesByPro",
          type: "Checkbox",
          texts: [
            "L'état des lieux a été réalisé par un professionnel mandaté",
          ],
        },
        {
          name: "rentalOthersFees",
          type: "Checkbox",
          texts: ["Il y a eu d'autres prestations facturées au bailleur"],
        },
      ],
    },
  ],
};

type typeUnion =
  | "Checkbox"
  | "InputSelect"
  | "InputText"
  | "Radio"
  | "InputDate"
  | "DayMonthSelect";

type TTemplateEditorsBase = {
  body: {
    title: string;
    elements: TTemplateEditorsBaseElements[];
  }[];
};

export type TTemplateEditorsBaseElements = {
  name: string;
  type: typeUnion;
  title?: string;
  texts?: string[];
  condition?: { key: string; value: string[] };
};

export type TTemplateEditors = {
  bail: TTemplateEditorsBase;
};

const data: TTemplateEditors = { bail };

export default data;
