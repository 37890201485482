import { TCollaboratorFormData } from "../types/collaborators/collaboratorsType";

import { TBaseResult, TResultWithData } from "../types/general/generalType";

import { _fetch } from "../utils/fetch";

export const getCollaborators = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/collaborators/get-collaborators",
      "GET-COLLABORATORS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const addNewCollaborators = (
  collaboratorEmail: string,
  callback: (res: TBaseResult & { isExist?: boolean }) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/collaborators/new-collaborator",
      "NEW-COLLABORATOR",
      JSON.stringify({ collaboratorEmail }),
      dispatch,
      callback
    );
  };
};

export const validateNewCollaborators = (
  data: Pick<TCollaboratorFormData, "email" | "name" | "password"> & {
    token: string;
  },
  callback: (res: TBaseResult & { isExist?: boolean }) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/collaborators/validate-collaborator",
      "VALIDATE-COLLABORATOR",
      JSON.stringify(data),
      dispatch,
      callback
    );
  };
};

export const getTokenData = (
  data: { token: string },
  callback: (res: TResultWithData) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/collaborators/get-token-data",
      "GET-TOKEN-COLLABORATOR",
      JSON.stringify(data),
      dispatch,
      callback
    );
  };
};
