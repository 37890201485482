import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Modal,
  Table,
  InputText,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import moment from "moment";

import { getHistories, sendHistory } from "../../../actions/historyAction";
import { getDocument } from "../../../actions/generatorAction";

import { useAppDispatch } from "../../../reducers";

import { getFromValue, getUrl } from "../../../utils/general";

import { InputChange } from "../../../types/general/generalType";
import { Col, Row } from "react-flexbox-grid";
interface ModalHistoriesProps {
  onClose: () => void;
  tenantId?: number;
  housingId?: number;
}

function ModalHistories({ onClose, tenantId, housingId }: ModalHistoriesProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getHistories(tenantId, housingId));
  }, []);

  const { histories } = useSelector((state: any) => state.historyReducer);
  const { tenants } = useSelector((state: any) => state.tenantReducer);
  const { housings } = useSelector((state: any) => state.housingReducer);

  const [paid, setPaid] = useState("");
  const [nameAdditional, setNameAdditional] = useState("");
  const [valueAdditional, setValueAdditional] = useState("");

  let data = [];

  let startDate = moment("2023-05-01");
  let endDate = moment().add(1, "month");

  if (startDate < endDate) {
    var date = endDate.endOf("month");

    while (date > startDate.startOf("month")) {
      let echeance = null;
      let quittance = null;

      histories.forEach((history: any, idx: number) => {
        if (
          history.fileMetadata &&
          moment(history.fileMetadata.dateStart).format("YYYY-MM") ===
            date.format("YYYY-MM")
        ) {
          if (history.type === "echeance") {
            echeance = idx;
          } else if (history.type === "quittance") {
            quittance = idx;
          }
        }
      });

      data.push({
        id: data.length,
        idx: data.length + 1,
        date: date.format("YYYY-MM"),
        file_echeance: echeance,
        file_quittance: quittance,
      });
      date.subtract(1, "month");
    }
  }

  return (
    <Modal target="root">
      <Flex
        classNames="shadow"
        onClick={(e) => e?.stopPropagation()}
        direction="column"
        styles={{
          width: 900,
          maxHeight: "calc(100% - 40px)",
          maxWidth: "calc(100% - 40px)",
          gap: 20,
        }}
      >
        <Table
          title="Historique"
          context={null}
          headerComponent={
            <Button text="Fermer" outline onClick={() => onClose()} />
          }
          columns={[
            {
              key: "idx",
              label: "#",
            },
            {
              key: "date",
              label: "Date",
            },
            {
              key: "file_echeance",
              label: "Avis d'échéance",
              component: (line) => {
                const housing = getFromValue(housings, "id", housingId || -1);
                const tenant = getFromValue(tenants, "id", housing.tenantId);

                return line.file_echeance !== null ? (
                  <div style={{ display: "flex", gap: 10 }}>
                    <Button
                      text="Télécharger"
                      onClick={() => {
                        window.open(
                          `${getUrl()}/api/file/get-file/${
                            histories[line.file_echeance].fileToken
                          }`,
                          "_blank"
                        );
                      }}
                    />
                    <Button
                      outline={
                        histories[line.file_echeance]
                          ? histories[line.file_echeance].countMail
                          : null
                      }
                      text="Envoyer"
                      onClick={() => {
                        const history = histories[line.file_echeance];
                        if (
                          window.confirm(
                            "Êtes-vous sur de vouloir envoyer le document par mail"
                          )
                        ) {
                          dispatch(
                            sendHistory(
                              history.id,
                              history.tenantId,
                              history.type,
                              history.fileToken,
                              history.fileMetadata
                            )
                          );
                        }
                      }}
                    />
                  </div>
                ) : housingId && housing.tenantId ? (
                  <Button
                    outline
                    text="Générer avis d'échéance"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Êtes-vous sûr de vouloir générer l'avis d'échéance ?"
                        )
                      ) {
                        dispatch(
                          getDocument(
                            housing.tenantId,
                            housingId,
                            "AVIS_ECHEANCE",
                            housing.reference,
                            moment(`${line.date}-01`, "YYYY-MM-DD")
                              .startOf("month")
                              .format("YYYY-MM-DD"),
                            moment(`${line.date}-01`, "YYYY-MM-DD")
                              .endOf("month")
                              .format("YYYY-MM-DD"),
                            parseFloat(housing.rent),
                            undefined,
                            parseFloat(housing.charge),
                            nameAdditional,
                            parseFloat(valueAdditional),
                            `${tenant.name} ${tenant.surname}`,
                            housing.address,
                            housing.zipCode,
                            housing.city,
                            tenant.phone,
                            tenant.email
                          )
                        );
                      }
                    }}
                  />
                ) : (
                  <></>
                );
              },
            },
            {
              key: "file_quittance",
              label: "Quittance",
              component: (line) => {
                const housing = getFromValue(housings, "id", housingId || -1);
                const tenant = getFromValue(tenants, "id", housing.tenantId);

                return line.file_quittance !== null ? (
                  <div style={{ display: "flex", gap: 10 }}>
                    <Button
                      text="Télécharger"
                      onClick={() => {
                        window.open(
                          `${getUrl()}/api/file/get-file/${
                            histories[line.file_quittance].fileToken
                          }`,
                          "_blank"
                        );
                      }}
                    />
                    <Button
                      outline={
                        histories[line.file_quittance]
                          ? histories[line.file_quittance].countMail
                          : null
                      }
                      text="Envoyer"
                      onClick={() => {
                        const history = histories[line.file_quittance];
                        if (
                          window.confirm(
                            "Êtes-vous sur de vouloir envoyer le document par mail"
                          )
                        ) {
                          dispatch(
                            sendHistory(
                              history.id,
                              history.tenantId,
                              history.type,
                              history.fileToken,
                              history.fileMetadata
                            )
                          );
                        }
                      }}
                    />
                  </div>
                ) : housingId && housing.tenantId ? (
                  <div style={{ display: "flex", gap: 10 }}>
                    <Button
                      outline
                      text="Générer quittance"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Êtes-vous sûr de vouloir générer la quittance ?"
                          )
                        ) {
                          dispatch(
                            getDocument(
                              housing.tenantId,
                              housingId,
                              "QUITTANCE",
                              housing.reference,
                              moment(`${line.date}-01`, "YYYY-MM-DD")
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              moment(`${line.date}-01`, "YYYY-MM-DD")
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                              parseFloat(housing.rent),
                              paid
                                ? parseFloat(paid)
                                : parseFloat(housing.rent) +
                                    parseFloat(housing.charge) +
                                    (parseFloat(housing.valueAdditional) || 0),
                              parseFloat(housing.charge),
                              nameAdditional,
                              parseFloat(valueAdditional),
                              `${tenant.name} ${tenant.surname}`,
                              housing.address,
                              housing.zipCode,
                              housing.city,
                              tenant.phone,
                              tenant.email
                            )
                          );
                        }
                      }}
                    />
                  </div>
                ) : (
                  <></>
                );
              },
            },
          ]}
          data={data}
        />
        <Row>
          <Col xs={12} md={6} lg={8}>
            <InputText
              className="w-auto"
              type="text"
              placeholder="Libellé charge supplémentaire"
              value={nameAdditional}
              onChange={({ target }: InputChange) => {
                setNameAdditional(target.value);
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <InputText
              className="w-auto"
              type="number"
              placeholder="Montant charge supplémentaire"
              value={valueAdditional}
              onChange={({ target }: InputChange) => {
                setValueAdditional(target.value);
              }}
            />
          </Col>
        </Row>

        <InputText
          className="w-auto"
          type="number"
          placeholder="Montant réglé"
          value={paid}
          onChange={({ target }: InputChange) => {
            setPaid(target.value);
          }}
        />
      </Flex>
    </Modal>
  );
}

export default ModalHistories;
