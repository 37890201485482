/* Libs */
import { Dispatch, useEffect, useState } from "react";
import classNames from "classnames";
import { Button, Flex, Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";

/* Components */
import SignUp from "../views/auth/Signup";
import Signin from "../views/auth/Signin";

/* Styles */
import styles from "../styles/auth/Auth.module.css";

import SmallLogo from "../images/icons/SmallLogo";

export default function Auth() {
  const navigate = useNavigate();

  const [transform, setTransform] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filterMobileUp, setFilterMobileUp] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth > 950 ? (
        <div className={styles.auth}>
          <div className={styles.auth_left_top} onClick={() => navigate("/")}>
            <SmallLogo
              size={40}
              color={transform ? "var(--primary-bg-color)" : "white"}
            />
          </div>
          <SignUp transform={transform} />
          <Signin transform={transform} />
          <Filter transform={transform} setTransform={setTransform} />
        </div>
      ) : (
        <div className={styles.auth_mobile}>
          {filterMobileUp ? (
            <SignUp transform={filterMobileUp} isMobile />
          ) : (
            <Signin transform={filterMobileUp} isMobile />
          )}
          <FilterMobile
            filterMobileUp={filterMobileUp}
            setFilterMobileUp={setFilterMobileUp}
          />
        </div>
      )}
    </>
  );
}

/** Private Components **/

type FilterProps = {
  transform: boolean;
  setTransform: Dispatch<React.SetStateAction<boolean>>;
};

const Filter = ({ transform, setTransform }: FilterProps) => {
  return (
    <div
      className={classNames(styles.auth_filter_main_container, {
        [styles.auth_filter_left]: transform,
        [styles.auth_filter_right]: !transform,
      })}
    >
      <Flex
        justify="center"
        direction="column"
        align="center"
        styles={{ height: "100%", padding: 10 }}
      >
        <h2 style={{ color: "white" }}>
          {transform ? "Déja un compte" : "Je n'ai pas de compte"}
        </h2>
        <p style={{ color: "white", textAlign: "center" }}>
          Cliquez sur le bouton et entrez vos informations personnelles pour
          vous {transform ? "connecter" : "inscrire"}
        </p>
        <Button
          style={{
            color: "white",
            border: "1px solid white",
          }}
          outline
          text={transform ? "Se Connecter" : "S'inscrire"}
          onClick={() => setTransform(!transform)}
        />
      </Flex>
    </div>
  );
};

type FilterMobileProps = {
  filterMobileUp: boolean;
  setFilterMobileUp: Dispatch<React.SetStateAction<boolean>>;
};

const FilterMobile = ({
  filterMobileUp,
  setFilterMobileUp,
}: FilterMobileProps) => {
  return (
    <div
      className={classNames(styles.auth_filter_main_container_mobile, {
        [styles.auth_filter_main_container_mobile_top]: filterMobileUp,
        [styles.auth_filter_main_container_mobile_bottom]: !filterMobileUp,
      })}
    >
      <Flex
        justify="center"
        direction={filterMobileUp ? "column-reverse" : "column"}
        align="center"
        styles={{ height: "100%", padding: 10, position: "relative" }}
      >
        <Icon
          style={
            !filterMobileUp
              ? { transform: "rotate(180deg)", padding: 0 }
              : { padding: 0 }
          }
          icon="ArrowDown"
          onClick={() => setFilterMobileUp(!filterMobileUp)}
        />
        <Flex direction="column" align="center" gap={10}>
          <h2 style={{ color: "white", margin: 0 }}>
            {filterMobileUp ? "Déjà un compte ?" : "Je n'ai pas de compte"}
          </h2>
          <p className={styles.auth_filter_mobile_texte}>
            {filterMobileUp
              ? "Glissez vers le haut et entrez vos informations personnelles pour vous connecter"
              : "Glissez vers le bas et entrez vos informations personnelles pour vous inscrire"}
          </p>
        </Flex>
      </Flex>
    </div>
  );
};
