/* Libs */
import {
  Button,
  Card,
  InputSelect,
  InputText,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { Col, Row } from "react-flexbox-grid";

/* Types */
import { TInput } from "../../../types/organization/organizationType";

/* Components */
import DayMonthSelect from "../../general/DayMonthSelect";

type Props<T> = {
  title: string;
  inputs: TInput<T>[];
  handleChange: (key: keyof T, value: string) => void;
  inputError?: string[];
  setInputError?: React.Dispatch<React.SetStateAction<string[]>>;
  onUpdate: () => void;
};

function OrganizationCard<T>(props: Props<T>) {
  const { title, inputs, handleChange, inputError, setInputError, onUpdate } =
    props;

  return (
    <Card title={title} className="h-100">
      <Row style={{ rowGap: 16 }}>
        {inputs.map((input) => {
          if (input.type === "text") {
            return input.required ? (
              <Col xs={12} md={6} lg={4}>
                <InputText
                  error={inputError && inputError.includes(input.key as string)}
                  placeholder={input.placeholder}
                  value={input.value}
                  onChange={(e) => {
                    if (
                      !e.target.value &&
                      inputError &&
                      !inputError.includes(input.key as string)
                    ) {
                      setInputError &&
                        setInputError(
                          (prev) => [...prev, input.key] as string[]
                        );
                    }
                    handleChange(input.key, e.target.value);
                  }}
                />
                {inputError && inputError.includes(input.key as string) && (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    Ce champ est obligatoire !
                  </span>
                )}
              </Col>
            ) : (
              <Col xs={12} md={6} lg={4}>
                <InputText
                  placeholder={input.placeholder}
                  value={input.value}
                  onChange={(e) => handleChange(input.key, e.target.value)}
                />
              </Col>
            );
          } else if (input.type === "select") {
            return (
              <Col xs={12} md={6} lg={4}>
                <InputSelect
                  label={input.label}
                  placeholder={input.placeholder}
                  options={input.options || []}
                  value={input.value}
                  onChange={(e) => handleChange(input.key, e as string)}
                />
              </Col>
            );
          } else if (input.type === "DayMonthSelect") {
            return (
              <Col xs={12} md={6} lg={4}>
                <DayMonthSelect
                  label={input.label}
                  value={input.value}
                  onChange={(e) => handleChange(input.key, e as string)}
                />
              </Col>
            );
          }
        })}
        <Col xs={12} className="d-flex">
          <Button
            disabled={inputError && !!inputError.length}
            style={{
              marginLeft: "auto",
            }}
            text="Enregistrer"
            onClick={onUpdate}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default OrganizationCard;
