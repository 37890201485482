export const initialState = {
  histories: <any>[],
  mails: <any>[],
};

export const historyReducer = (state = initialState, action: any) => {
  let tmpHistories = state.histories;
  switch (action.type) {
    case "GET-HISTORIES":
      return {
        ...state,
        histories: action.success ? action.data : [],
      };
    case "GET-MAILS":
      return {
        ...state,
        mails: action.success ? action.data : [],
      };
    case "SEND-HISTORY":
      tmpHistories.forEach((history: any) => {
        if (history.id === action.body.historyId) {
          history.countMail += 1;
        }
      });

      return {
        ...state,
        histories: tmpHistories,
      };
    default:
      return state;
  }
};
