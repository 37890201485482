import { useState, useEffect } from "react";
import { Card, Flex } from "@sahadevia/ui-kit-modern-sahadevia";

import "../styles/gauge-v2.css";

interface GaugeProps {
  title: string;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: string) => void;
}

function Gauge({ title, min, max, step, value, onChange }: GaugeProps) {
  const [isActive, setIsActive] = useState(false);

  const eventMouseUp = () => {
    if (isActive) {
      window.removeEventListener("mouseup", eventMouseUp, true);
      setIsActive(false);
    }
  };

  const onMouse = (e: React.MouseEvent<HTMLSpanElement>, isForce = false) => {
    if (isActive || isForce) {
      const currentTargetRect = e.currentTarget.getBoundingClientRect();
      const width = currentTargetRect.width;
      const positionX = e.pageX - currentTargetRect.left;

      let tmpValue = ((positionX * max) / width) * 100;

      let newPosition = tmpValue - (tmpValue % (step * 100));
      newPosition = Math.round(newPosition) / 100;

      if (newPosition > max) {
        newPosition = max;
      } else if (newPosition < min) {
        newPosition = min;
      }

      onChange(newPosition.toString());
    }
  };

  useEffect(() => {
    if (isActive) {
      window.addEventListener("mouseup", eventMouseUp, true);
    }
  }, [isActive]);

  return (
    <div>
      <Flex display="flex" align="center" justify="space-between">
        <p>{title}</p>
        <div>
          <input
            type="text"
            value={value.toString() || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value)
            }
          />
        </div>
      </Flex>
      <span
        className="gauge-v2"
        onMouseMove={(e) => onMouse(e)}
        onClick={(e) => onMouse(e, true)}
        // onMouseUp={() => onMouseUp && onMouseUp(value)}
        onMouseDown={(e) => {
          e.preventDefault();
          setIsActive(true);
        }}
      >
        <span>
          <span className="gauge-v2-rect" tabIndex={0}></span>
        </span>

        <span
          className="gauge-v2-bar"
          style={{
            left: "0px",
            width: `${(value / max) * 100 + 2}%`,
          }}
        ></span>

        <span
          className="gauge-v2-circle"
          style={{ left: `${(value / max) * 100}%` }}
        >
          <i></i>
          <i></i>
          <i></i>
        </span>
      </span>
    </div>
  );
}

export default Gauge;
