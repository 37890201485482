/* Libs */
import { useEffect, useState } from "react";
import { Flex } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

/* Reducers */
import { useAppDispatch } from "../../reducers";

/* Types */
import { TbaseReducer } from "../../types/reducers/reducersType";
import { TBankInfo } from "../../types/bank/bankType";
import { TInput } from "../../types/organization/organizationType";
import { TCompanyInfo, TRecallInfo } from "../../types/auth/authType";

/* Components */
import OrganizationCard from "../../components/dashboard/organization/OrganizationCard";
import Subscription from "../../components/dashboard/organization/Subscription";
import DocumentsTable from "../../components/dashboard/organization/DocumentsTable";

/* Actions */
import { updateCompany } from "../../actions/authAction";
import { getBankInfo } from "../../actions/bankAction";
import { getOrganizationDocuments } from "../../actions/organizationAction";

function Organization() {
  const dispatch = useAppDispatch();
  const { companyInfo, recallInfo } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const { bankInfo } = useSelector((state: TbaseReducer) => state.bankReducer);

  const [inputError, setInputError] = useState<string[]>([]);
  const dateMonthSelect = Array.from({ length: 28 }, (_, index) => ({
    text: (index + 1).toString(),
    value: index + 1,
  }));

  const {
    name,
    address1,
    address2,
    city,
    type,
    capital,
    tva,
    zipcode,
    siret,
    phone,
    email,
  } = companyInfo;

  const { receiptDate, dueDate, reviewDate } = recallInfo;
  const { iban, bic } = bankInfo;

  const handleChange = (key: keyof TCompanyInfo, value: string) => {
    if (inputError.length && inputError.includes(key)) {
      setInputError(inputError.filter((inputKey) => inputKey !== key));
    }

    const newData = { ...companyInfo, [key]: value };
    dispatch({ type: "UPDATE-COMPANY", data: newData });
  };

  const onUpdateCompany = () => {
    dispatch(updateCompany(companyInfo));
  };

  const onUpdateRecall = () => {
    dispatch(updateCompany(recallInfo));
  };

  const onUpdateBankInfo = () => {
    dispatch(updateCompany(bankInfo));
  };

  const handleRecallChange = (key: keyof TRecallInfo, value: string) => {
    const newData = { ...recallInfo, [key]: value };
    dispatch({ type: "UPDATE-RECALL", data: newData });
  };

  const handleBankChange = (key: keyof TBankInfo, value: string) => {
    const newData = { ...bankInfo, [key]: value };
    dispatch({ type: "UPDATE-BANK-INFO", data: newData });
  };

  useEffect(() => {
    if (!name.length) {
      setInputError(["name"]);
    } else if (!email.length) {
      setInputError(["email"]);
    }
    dispatch(getOrganizationDocuments());
    dispatch(getBankInfo());
  }, []);

  const InformationsInput: TInput<TCompanyInfo>[] = [
    {
      type: "text",
      placeholder: "Nom",
      value: name,
      key: "name",
      required: true,
    },
    {
      type: "text",
      placeholder: "Adresse 1",
      value: address1,
      key: "address1",
    },
    {
      type: "text",
      placeholder: "Adresse 2",
      value: address2,
      key: "address2",
    },
    {
      type: "text",
      placeholder: "Code postal",
      value: zipcode,
      key: "zipcode",
    },
    {
      type: "text",
      placeholder: "Ville",
      value: city,
      key: "city",
    },
    {
      type: "select",
      placeholder: "Veuillez sélectionner un type",
      value: type,
      options: [
        { text: "Personne physique", value: "Personne physique" },
        { text: "EURL", value: "EURL" },
        { text: "SCI", value: "SCI" },
        { text: "SAS", value: "SAS" },
        { text: "SASU", value: "SASU" },
        { text: "SARL", value: "SARL" },
      ],
      key: "type",
    },
    {
      type: "text",
      placeholder: "SIRET",
      value: siret,
      key: "siret",
    },
    {
      type: "text",
      placeholder: "N° TVA",
      value: tva,
      key: "tva",
    },
    {
      type: "text",
      placeholder: "Capital",
      value: capital,
      key: "capital",
    },
    {
      type: "text",
      placeholder: "E-mail",
      value: email,
      key: "email",
      required: true,
    },
    {
      type: "text",
      placeholder: "N° téléphone",
      value: phone,
      key: "phone",
    },
  ];

  const InformationsBankInput: TInput<TBankInfo>[] = [
    {
      type: "text",
      placeholder: "IBAN",
      value: iban,
      key: "iban",
    },
    {
      type: "text",
      placeholder: "BIC",
      value: bic,
      key: "bic",
    },
  ];

  const recallInput: TInput<TRecallInfo>[] = [
    {
      type: "select",
      placeholder: "Veuillez sélectionner un jour",
      label: "Date quittance",

      value: receiptDate,
      options: dateMonthSelect,
      key: "receiptDate",
    },
    {
      type: "select",
      placeholder: "Veuillez sélectionner un jour",
      label: "Date avis d'échéance",
      value: dueDate,
      options: dateMonthSelect,
      key: "dueDate",
    },
    {
      type: "DayMonthSelect",
      placeholder: "Veuillez sélectionner un jour",
      label: "Date révision loyer",
      value: reviewDate,
      key: "reviewDate",
    },
  ];

  return (
    <>
      <Flex classNames="justify-between">
        <h2 className="m-0">Mon Organisation</h2>
      </Flex>
      <OrganizationCard
        title={"Informations"}
        inputs={InformationsInput}
        handleChange={handleChange}
        inputError={inputError}
        setInputError={setInputError}
        onUpdate={onUpdateCompany}
      />
      <OrganizationCard
        title={"Informations Bancaires"}
        inputs={InformationsBankInput}
        handleChange={handleBankChange}
        onUpdate={onUpdateBankInfo}
      />
      <OrganizationCard
        title={"Rappels"}
        inputs={recallInput}
        handleChange={handleRecallChange}
        onUpdate={onUpdateRecall}
      />
      <Subscription />
      <DocumentsTable />
    </>
  );
}

export default Organization;
