import { useEffect, useState } from "react";
import { Button, Flex, Icon, Table } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { getTenants } from "../../actions/tenantAction";

import ModalTenant from "../../components/dashboard/modal/ModalTenant";
import ModalHistories from "../../components/dashboard/modal/ModalHistories";
import ModalFiles from "../../components/dashboard/modal/ModalFiles";

function Tenants() {
  const dispatch = useAppDispatch();

  const [isModalTenant, setIsModalTenant] = useState(false);
  const [tenantIdModalHistories, setTenantIdModalHistories] = useState(0);
  const [tenantIdModalFiles, setTenantIdModalFiles] = useState(0);

  useEffect(() => {
    dispatch(getTenants());
  }, []);

  const { tenants } = useSelector((state: any) => state.tenantReducer);

  return (
    <>
      <h2 className="m-0">Locataires</h2>
      <Table
        title="Autres"
        headerComponent={
          <Button
            text="Ajouter un locataire"
            onClick={() => setIsModalTenant(true)}
          />
        }
        context={null}
        columns={[
          {
            key: "idx",
            label: "#",
          },
          {
            key: "kind",
            label: "Genre",
          },
          {
            key: "name",
            label: "Nom",
          },
          {
            key: "surname",
            label: "Prénom",
          },
          {
            key: "email",
            label: "E-mail",
          },
          {
            key: "phone",
            label: "Téléphone",
          },
          {
            key: "actions",
            label: "Actions",
            component: (line) => (
              <Flex gap={10}>
                <Icon
                  infoText="Historique"
                  backgroundHover
                  size={16}
                  icon="Search"
                  onClick={() => setTenantIdModalHistories(line.tenantId)}
                />
                <Icon
                  infoText="Fichiers"
                  backgroundHover
                  size={16}
                  icon="File"
                  onClick={() => setTenantIdModalFiles(line.tenantId)}
                />
              </Flex>
            ),
          },
        ]}
        data={tenants.map((tenant: any, idx: number) => ({
          idx: idx + 1,
          tenantId: tenant.id,
          kind: tenant.kind,
          name: tenant.name,
          surname: tenant.surname,
          email: tenant.email,
          phone: tenant.phone,
        }))}
      />
      {isModalTenant ? (
        <ModalTenant onClose={() => setIsModalTenant(false)} />
      ) : (
        <></>
      )}
      {tenantIdModalHistories ? (
        <ModalHistories
          onClose={() => setTenantIdModalHistories(0)}
          tenantId={tenantIdModalHistories}
        />
      ) : (
        <></>
      )}
      {tenantIdModalFiles ? (
        <ModalFiles
          onClose={() => setTenantIdModalFiles(0)}
          tenantId={tenantIdModalFiles}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Tenants;
